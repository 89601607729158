import { useStore } from 'vuex';
import { computed } from 'vue';
import { ProductionType } from '@/hooks/useConstructor';
import { DictsState } from '@/store/modules/dicts';
import { ImportFileTemplate } from '@/store/modules/layout';

export type Dict<T> = Array<T>;

export enum DictType {
  smsServices ='smsServices',
  companyModuleType= 'companyModuleType',
  companyServices = 'companyServices',
  companyTaxationSystems = 'companyTaxationSystems',
  companyPeniCalculationTypes = 'companyPeniCalculationTypes',
  companyDebtorStatuses = 'companyDebtorStatuses',
  companyDebtorSubstatuses = 'companyDebtorSubstatuses',
  employeePositions = 'employeePositions',
  employeeRoles = 'employeeRoles',
  files = 'files',
  debtorStatuses = 'debtorStatuses',
  debtorSubstatuses = 'debtorSubstatuses',
  debtorStatusesForFilters = 'debtorStatusesForFilters',
  debtorSubstatusesForFilters = 'debtorSubstatusesForFilters',
  tenantRelationships = 'tenantRelationships',
  penaltyKeyRates = 'penaltyKeyRates',
  dataUploadTemplates = 'dataUploadTemplates',
  employeeEventNotifications = 'employeeEventNotifications',
  baseTypeOptions = 'baseTypeOptions',
  complaintTypes = 'complaintTypes',
}

export type DataUploadTemplate = {
  id: number;
  production_type: ProductionType;
  template_type: 'linear'|'tabular';
  extension_type: ImportFileTemplate;
  file: string;
}

export type DictDebtorSubstatus = {
  label: string;
  production_type: ProductionType;
  status: string;
  value: string;
  group: string;
  info: string;
}

export type DictMap<T, Label extends keyof T> = {
  [key in keyof T]: T[Label]
}

export const useDicts = () => {
  const store = useStore();

  const getDict = <T>(type: DictType) => computed<Dict<T>>(() => (
    store.getters['dicts/getDict'](type)
  ));

  const getDictMap = <T>(type: DictType) => computed<DictMap<T, keyof T>>(
    () => (
      store.getters['dicts/getDictMap'](type)
    ),
  );

  const dictsMap = computed<DictsState['dicts']>(() => store.getters['dicts/dictsMap']);

  return {
    getDict,
    getDictMap,
    dictsMap,
  };
};
