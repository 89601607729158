import {
  ComputedRef, Ref, ref, watchEffect,
} from 'vue';
import { DebtorDefaultDocumentType, useDocumentsApi } from '@/hooks/useDocumentsApi';
import { ProductionType } from '@/hooks/useConstructor';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useTabKeys } from '@/components/dialog/dialogs/debtor/tabs/documents/useTabKeys';
import { DocumentTab } from '@/components/dialog/dialogs/debtor/tabs/documents/types';
import { differenceBy } from 'lodash-es';

export const useDebtorDefaultDocuments = (productionType: Ref<ProductionType>, companyId: ComputedRef<number | undefined>) => {
  const isLoading = ref<boolean>(false);
  const documentTypes = ref<DebtorDefaultDocumentType[]>([]);
  const defaultTabs = ref<Array<DocumentTab>>([]);
  const tabKeys = useTabKeys(productionType);
  const { t } = useLocalI18n('debtor.documents');

  const {
    fetchDebtorDefaultDocumentTypes,
    createDebtorDefaultDocumentType,
    updateDebtorDefaultDocumentType,
  } = useDocumentsApi();

  const fetchDefaultDocTypes = async () => {
    isLoading.value = true;
    const { status, response } = await fetchDebtorDefaultDocumentTypes({
      filters: {
        company: companyId.value as number,
        production_type: productionType.value,
      },
      page: 1,
      limit: 100,
    });

    if (status) {
      documentTypes.value = response.results.map((doc) => ({
        ...doc,
        name: t(`${doc.key}.tab`),
      }));
      console.log(documentTypes.value, 'documentTypes.value87457234651723654')
    }

    if (!response.results.length) {
      const results = await Promise.all(tabKeys.value
        .map((t, i) => (
          createDebtorDefaultDocumentType({
            production_type: productionType.value,
            key: t.key,
            order_number: i + 1,
            is_show: true,
            company: companyId.value!,
          }).then(({ response }) => response))));

      documentTypes.value = results.map((doc) => ({
        ...doc,
        name: t(`${doc.key}.tab`),
      }));
    } else {
      const newDocumentTypes = differenceBy(tabKeys.value, response.results, 'key');

      if (newDocumentTypes.length) {
        const results = await Promise.all(newDocumentTypes
          .map((t, i) => (
            createDebtorDefaultDocumentType({
              production_type: productionType.value,
              key: t.key,
              order_number: documentTypes.value.length + i + 1,
              is_show: true,
              company: companyId.value!,
            }).then(({ response }) => response))));

        results.map((doc) => documentTypes.value.push({
          ...doc,
          name: t(`${doc.key}.tab`),
        }));
      }
    }
    isLoading.value = false;
  };

  watchEffect(() => {
    if (!documentTypes.value.length) {
      return;
    }
    defaultTabs.value = documentTypes.value
      .filter(({ is_show }) => Boolean(is_show))
      .map((tab) => ({
        ...tab,
        label: tab.name ?? t(`${tab.key}.tab`),
        id: `modal_debtor_documents_tab_${tab.key}_tab`,
        order_number: tab.order_number,
      })) || [];
  });

  return {
    isLoading,
    defaultDocumentTypes: documentTypes,
    fetchDefaultDocTypes,
    updateDebtorDefaultDocumentType,
    defaultTabs,
  };
};
