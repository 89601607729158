import { computed, Ref } from 'vue';
import { DataType, ImportTypeKey } from '@/pages/exchange/import/importTypes';
import { RouteRecordRaw } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { PermissionsModel } from '@/hooks/useCompanyPermissionsApi';

export function useImportKeys(
  permissionsMap: Ref<PermissionsModel>,
  companyModuleType: Ref<CompanyModuleType>,
) {
  const { t } = useI18n();

  const importTypes = computed<Array<DataType<ImportTypeKey>>>(() => ((([
    {
      key: ImportTypeKey.instruction,
    },
    permissionsMap.value.pretrial === 'write' && {
      key: ImportTypeKey.pretrial,
    },
    permissionsMap.value.judicial === 'write' && {
      key: ImportTypeKey.judicial,
    },
    permissionsMap.value.executive === 'write' && {
      key: ImportTypeKey.executive,
    },
    permissionsMap.value.judicial === 'write' && {
      key: ImportTypeKey.paymentOrder,
      description: t(`exchange.dataTypeDescription.${ImportTypeKey.paymentOrder}`),
    },
    [permissionsMap.value.pretrial, permissionsMap.value.judicial].includes('write') && {
      key: ImportTypeKey.returnGP,
    },
    permissionsMap.value.judicial === 'write' && {
      key: ImportTypeKey.judgment,
      description: t(`exchange.dataTypeDescription.${ImportTypeKey.judgment}`),
    },
    permissionsMap.value.judicial === 'write' && companyModuleType.value !== 2 && {
      key: ImportTypeKey.housebook,
      description: t(`exchange.dataTypeDescription.${ImportTypeKey.housebook}`),
    },
    permissionsMap.value.judicial === 'write' && companyModuleType.value !== 2 && {
      key: ImportTypeKey.oosProtocol,
      description: t(`exchange.dataTypeDescription.${ImportTypeKey.oosProtocol}`),
    },
    companyModuleType.value === 2 && {
      key: ImportTypeKey.photos,
    },
    companyModuleType.value === 4 && {
      key: ImportTypeKey.loanAgreement,
      label: 'Кредитный договор',
    },
    permissionsMap.value.judicial === 'write' && {
      key: ImportTypeKey.contract,
      label: companyModuleType.value === 4 ? 'Договор цессии' : 'Договор',
    },
    [permissionsMap.value.pretrial, permissionsMap.value.judicial].includes('write') && {
      key: ImportTypeKey.invoice,
    },
    permissionsMap.value.judicial === 'write' && {
      key: ImportTypeKey.egrn,
    },
    [permissionsMap.value.pretrial, permissionsMap.value.judicial, permissionsMap.value.executive].includes('write') && {
      key: ImportTypeKey.pochta,
    },
  ]).filter(Boolean) as { key: ImportTypeKey; label?: string }[]).map((type) => ({
    ...type,
    label: type.label ?? t(`exchange.dataType.${type.key}`),
    to: {
      params: {
        dataType: type.key,
      },
    } as Partial<RouteRecordRaw>,
  }))));

  return { importTypes };
}
