import { useApi } from '@/hooks/useApi';
import { ref } from 'vue';
import { ApiCommand } from '@/store/modules/api';

export function useCustomLocales() {
  const apiRequest = useApi();
  let fetched = false;

  const locales = ref<Data>({
    ru: {
      'debtor.history.menu': {},
      'debtor.history.value': {},
      'debtor.history.value_tab': {},
      'account.report.type': {},
      'debtor.history.status': {},
      'debtor.documents.column': {},
      'debtor.courts.complaint_type': {},
      'debtor.courts.executive_type': {},
      'debtor.voice_document.status': {},
      'debtor.courts.court_order_type': {},
      'debtor.courts.judicial_type': {},
      'debtor.court_decision.decisions': {},
      'debtor.courts.status': [],
    },
  });

  type Data = {
    ru: {
      'debtor.history.menu': Record<string, string>;
      'debtor.history.value': Record<string, string>;
      'debtor.history.value_tab': Record<string, string>;
      'account.report.type': Record<string, string>;
      'debtor.history.status': Record<string, string>;
      'debtor.documents.column': Record<string, string>;
      'debtor.courts.complaint_type': Record<string, string>;
      'debtor.courts.executive_type': Record<string, string>;
      'debtor.voice_document.status': Record<string, string>;
      'debtor.courts.court_order_type': Record<string, string>;
      'debtor.courts.judicial_type': Record<string, string>;
      'debtor.court_decision.decisions': Record<string, string>;
      'debtor.courts.status': string[];
    };
  }

  const translateHistoryValue = (type: 'menu' | 'value_tab' | 'status' | 'value', key: string) => {
    return locales.value.ru[`debtor.history.${type}`][key];
  };

  const translateColumn = (key: string) => {
    const result = locales.value.ru['debtor.documents.column'][key];
    if (!result) {
      console.warn('no translation', key);
    }
    return result || key;
  };

  const translateComplaintType = (key: string) => {
    const result = locales.value.ru['debtor.courts.complaint_type'][key];
    if (!result) {
      console.error('no translation', key);
    }
    return result ?? key;
  };

  const fetchVoiceStatuses = async () => {
    await fetchLocales();
    return Object.entries(locales.value.ru['debtor.voice_document.status'])
      .map(([value, label]) => ({ label, value }));
  };

  const fetchLocales = async (
  ) => !fetched && apiRequest<Data>({
    command: ApiCommand.fetchCommonLocales,
  }).then((data) => {
    console.log('fetched server translations', data);
    locales.value = data.response;
    fetched = true;
  });

  return {
    locales,
    fetchLocales,
    translateColumn,
    translateHistoryValue,
    translateComplaintType,
    dc: translateColumn,
    dh: translateHistoryValue,
    fetchVoiceStatuses,
  };

}
