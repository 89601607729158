import {
  onBeforeUnmount, Ref, ref, watch,
} from 'vue';
import { useInjectDebtorDialog } from '@/components/dialog/dialogs/debtor/useInjectDebtorDialog';
import { useDocumentsApi } from '@/hooks/useDocumentsApi';
import { ActiveTabKey, DocumentTab } from '@/components/dialog/dialogs/debtor/tabs/documents/types';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { ApiResponse, ListingResponse } from '@/store/modules/api';
import { SignalType, useSignal } from '@/hooks/useSignal';
import {
  useDocsCommonRequests,
} from '@/components/dialog/dialogs/debtor/tabs/documents/common-requests';
import { isNumber } from '@/utils/number';

export const useEmptyDocumentTabs = (tabs: Ref<Array<DocumentTab>>, customTabKeysReqs: Ref<Record<any, any>>) => {
  const {
    subscribeToSignal,
  } = useSignal();
  const { debtor, productionType } = useInjectDebtorDialog();
  const { companyId } = useProtectedDefaultCompany();
  const tabsWithDocuments = ref<Partial<Record<ActiveTabKey, string>>>({});

  const apiEndpoints = useDocumentsApi();
  const requests = useDocsCommonRequests();

  const fetchEmptyDocuments = async () => {
    const endpointsMap = {
      ...requests,
      egrn_data: apiEndpoints.fetchEgrnData,
      egrn_rights: apiEndpoints.fetchEgrnRights,
      fee: apiEndpoints.fetchFeePayments,
      protocol_ooc: (params: any) => {
        const { production_type, ...restFilters } = params.filters;
        return apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...restFilters, key: 'protocol_ooc' } },
        );
      },
      preloaded_charges: (params: any) => {
        const { production_type, ...restFilters } = params.filters;
        return apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...restFilters, key: 'preloaded_charges' } },
        );
      },
      preloaded_penny: (params: any) => {
        const { production_type, ...restFilters } = params.filters;
        return apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...restFilters, key: 'preloaded_penny' } },
        );
      },
      guarantee_letter: (params: any) => {
        const { production_type, ...restFilters } = params.filters;
        return apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...restFilters, key: 'guarantee_letter' } },
        );
      },
      act: (params: any) => {
        const { production_type, ...restFilters } = params.filters;
        return apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...restFilters, key: 'act' } },
        );
      },
      agreements: apiEndpoints.fetchDebtorAgreements,
      judgments: (params: any) => {
        const { production_type, ...filters } = params.filters;
        return apiEndpoints.fetchJudgments(
          { ...params, filters },
        );
      },
      pochta: (params: any) => {
        const { filters, ...restParams } = params;
        const { production_type, ...restFilters } = filters;
        return apiEndpoints.fetchDebtorPochtaTracks({
          ...restParams,
          filters: restFilters,
        });
      },
      my_documents: apiEndpoints.fetchMyDocuments,
      invoice: (params: any) => {
        const { production_type, ...restFilters } = params.filters;
        return apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...restFilters, key: 'invoice' } },
        );
      },
      settlement_statements: (params: any) => {
        const { production_type, ...restFilters } = params.filters;
        return apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...restFilters, key: 'settlement_statements' } },
        );
      },
      technical_passport: (params: any) => {
        const { production_type, ...restFilters } = params.filters;
        return apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...restFilters, key: 'technical_passport' } },
        );
      },
      inspection_report: (params: any) => {
        const { production_type, ...restFilters } = params.filters;
        return apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...restFilters, key: 'inspection_report' } },
        );
      },
      returnGP: (params: any) => {
        const { production_type, ...restFilters } = params.filters;
        return apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...restFilters, key: 'returnGP' } },
        );
      },
      submit_to_court: (params: any) => {
        const { production_type, ...restFilters } = params.filters;
        return apiEndpoints.fetchUniqueDebtorsDocuments(
          { ...params, filters: { ...restFilters, key: 'submit_to_court' } },
        );
      },
      ...customTabKeysReqs.value,
    } as {
      [key in ActiveTabKey]: any
    };
    const results = await Promise.all(
      tabs.value.map(({ key }) => (endpointsMap[key]({
        filters: {
          debtor_id: debtor.value?.debtor.pk,
          production_type: productionType.value,
          ...(isNumber(key) ? { type: key } : {
            debtor_ids: [debtor.value?.debtor.pk],
            company_id: companyId.value,
          }),
        },
        limit: 1,
        page: 1,
      }) as Promise<ApiResponse<ListingResponse<any>>>).then((r) => {
        if (r.status) {
          return { key, status: r.response?.count ? 'success' : undefined };
        }
        return { key, status: 'success' };
      })),
    );
    tabsWithDocuments.value = Object.fromEntries(
      results.map(({ key, status }) => ([key, status])),
    );
  };

  watch([debtor, tabs], ([d, t]) => {
    if (d || t) {
      fetchEmptyDocuments();
    }
  }, { immediate: false });

  onBeforeUnmount(
    subscribeToSignal(SignalType.debtorDocumentsUpdated, fetchEmptyDocuments),
  );

  return tabsWithDocuments;
};
