import * as E from 'fp-ts/Either';
import * as O from 'fp-ts/Option';
import { Module, Plugin } from 'vuex';
import { RouteRecordRaw } from 'vue-router';
import {
  ChangePasswordModel,
  ChangePasswordResponse,
  PasswordModel,
  PasswordResponse,
  RegistrationData,
  RestoreModel,
  RestoreResponse,
  SignInResponse,
  SignUpModel,
  SignUpResponse,
  StoreJwtToken,
  UpdateUserDataModel,
  UserData,
  UserResponse,
  UserRole,
  UserToken,
  VerifyModel,
  VerifyResponse,
} from '@/hooks/useUser';
import { StoreState } from '@/store';
import { ApiCommand, ApiRequest, ApiResponse } from '@/store/modules/api';
import { isAtLeastPartialPhone } from '@/utils/string';
import { FetchCompaniesResponse } from '@/hooks/useCompanies';
import { awaitFrame } from '@/utils/window';
import { SignalType } from '@/hooks/useSignal';
import { envIsMobile } from '@/utils/env';
import {
  getMergedPrefs, getMergedPrefsWithDefault, UserPrefs, UserPrefsDefault,
} from '@/hooks/useUserPrefs';
import {
  JwtContent,
  jwtContentToUserStore,
  jwtTokenIsExpired,
  parseJwtToken,
} from '@urrobot/core/service/tokenStorage';
import { AuthResponseSuccess, AuthService } from '@urrobot/core/service/authService';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';
import { wait } from '@/utils/common';

export type AuthGuard = {
  isAuthorized: boolean;
  guestRequired: boolean;
  authRequired: boolean;
  forceLogOut: boolean;
}

export type AuthGuardResponse = {
  result: boolean;
} & ({
  result: true;
} | {
  result: false;
  redirect: Partial<RouteRecordRaw>;
})

export type StandartizeCadnumLevel = 9|10|11|17

export type UserState = {
  customApiUrl: string|null;
  tokenIsRefreshing: boolean;
  token: StoreJwtToken | null;
  licenseToken: UserToken | null;
  standartizeToken: UserToken | null;
  data: UserData | null;
  registrationData: RegistrationData | null;
  codeUntil: Date | null;
  criticalDataIsLoaded: boolean;
  prefs : UserPrefs | null;
  isEulaAccepted: boolean | null;
  standartizeCadnumLevels: StandartizeCadnumLevel[] | null;
  canCheckoutBack: boolean;
}

type UserModule = Module<UserState, StoreState>;

export const namespaced = true;

let refreshingPromise: Promise<void>|null = null;
let resolveRefreshingPromise: (() => void);
let rejectRefreshingPromise: ((e: any) => void);

export const state: UserModule['state'] = () => ({
  customApiUrl: null,
  tokenIsRefreshing: false,
  token: null,
  licenseToken: null,
  standartizeToken: null,
  data: null,
  registrationData: null,
  codeUntil: null,
  criticalDataIsLoaded: false,
  prefs: null,
  isEulaAccepted: null,
  standartizeCadnumLevels: null,
  canCheckoutBack: false,
});

export const getters: UserModule['getters'] = {
  token: (state) => state.token,
  licenseToken: (state) => state.licenseToken,
  standartizeToken: (state) => state.standartizeToken,
  data: (state) => state.data,
  prefs: (state) => getMergedPrefsWithDefault((state.prefs || {}) as UserPrefs),
  isAuthorized: (state) => !!state.token,
  registrationData: (state) => state.registrationData,
  criticalDataIsLoaded: (state) => state.criticalDataIsLoaded,
  codeUntil: (state) => state.codeUntil,
  isEulaAccepted: (state) => state.isEulaAccepted,
};

export const mutations: UserModule['mutations'] = {
  setCustomApiUrl: (state, value: string|null) => {
    state.customApiUrl = value;
  },
  setTokenIsRefreshing: (state, value: boolean) => {
    state.tokenIsRefreshing = value;
  },
  setToken: (state, token: StoreJwtToken) => {
    if (token) {
      state.token = {
        access: token.access ?? state.token?.access,
        refresh: token.refresh ?? state.token?.refresh,
      };
    } else {
      state.token = null;
    }
  },
  setLicenseToken: (state, token: UserToken) => {
    state.licenseToken = token;
  },
  setStandartizeToken: (state, token: UserToken) => {
    state.standartizeToken = token;
  },
  setData: (state, data: UserData) => {
    state.data = data;
  },
  setPrefs: (state, data: UserPrefs) => {
    state.prefs = data;
  },
  setRegistrationData: (state, data: RegistrationData) => {
    state.registrationData = data;
  },
  setCodeUntil: (state, codeUntil: UserState['codeUntil']) => {
    state.codeUntil = codeUntil;
  },
  setCriticalDataIsLoaded: (state, value) => {
    state.criticalDataIsLoaded = value;
  },
  setIsEulaAccepted: (state, value: boolean) => {
    state.isEulaAccepted = value;
  },
  setStandartizeCadnumLevels: (state, value: StandartizeCadnumLevel[]|null) => {
    state.standartizeCadnumLevels = value;
  },
  setCanCheckoutBack: (state, value: boolean) => {
    state.canCheckoutBack = value;
  },
};

export const actions: UserModule['actions'] = {
  async fetchData({ dispatch, commit, getters }) {
    const [userResponse, prefsResponse] = await Promise.all([
      (dispatch('api/request', {
        command: ApiCommand.fetchAuthData,
        params: {
          id: getters.data?.id,
        },
      } as ApiRequest<UserData>, { root: true })) as unknown as ApiResponse<UserResponse>,
      (dispatch('api/request', {
        command: ApiCommand.getUserInterfaceSettings,
        params: {
          id: getters.data?.id,
        },
      } as ApiRequest<UserData>, { root: true })) as unknown as ApiResponse<UserPrefs>,
    ]);

    if (!userResponse.status) {
      if ((userResponse.response as any).code === 'user_not_found') {
        console.log('signOut');
        // commit('layout/signal', { signalType: SignalType.criticalDataIsLoaded }, { root: true });
        commit('layout/signal', { signalType: SignalType.userNotFound }, { root: true });
        dispatch('signOut');
        return;
      }
    }

    if (userResponse.status) {
      commit('setData', userResponse.response);
    }
    if (prefsResponse.status) {
      commit('setPrefs', prefsResponse.response);
      // @ts-ignore
      commit('setIsEulaAccepted', prefsResponse.response?.is_eula_accepted);
    }

    return userResponse;
  },
  async updateData({ dispatch, commit, getters }, model: UpdateUserDataModel) {
    const { status, response } = await (dispatch('api/request', {
      command: ApiCommand.updateUserData,
      params: {
        id: getters.data?.id,
      },
      data: model,
    } as ApiRequest<UserData>, { root: true })) as ApiResponse<UserData>;

    if (!status) {
      return { status, response };
    }

    commit('setData', response);

    return { status, response };
  },
  async acceptEula({ dispatch, getters, commit }, payload?: boolean) {
    const currentUserId = getters.data.id;
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.updateUserInterfaceSettings,
      data: { is_eula_accepted: payload !== undefined ? payload : true },
      params: {
        id: currentUserId,
      },
    } as ApiRequest<RestoreModel>, { root: true })) as ApiResponse<UserPrefsDefault>;
    if (status) {
      commit('setIsEulaAccepted', true);
    }
    return {
      status, response,
    };
  },
  async updatePrefs({
    commit, getters, state, dispatch,
  }, payload: Partial<UserPrefs>) {
    const oldPrefs = JSON.parse(JSON.stringify(getters.prefs));
    const merged = getMergedPrefs(
      payload,
      JSON.parse(JSON.stringify(getters.prefs)),
    );
    console.log('oldPrefs', oldPrefs);
    commit('setPrefs', merged);
    const currentUserId = getters.data.id;
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.updateUserInterfaceSettings,
      data: merged,
      params: {
        id: currentUserId,
      },
    } as ApiRequest<RestoreModel>, { root: true })) as ApiResponse<UserPrefsDefault>;
    commit('setPrefs', response);
    if (!status) {
      commit('setPrefs', oldPrefs);
    }
    return { status, response };
  },
  async signUp({ dispatch, commit }, model: SignUpModel) {
    if (!model.agreement) {
      return {
        status: false,
        response: {
          agreement: 'Подтвердите обработку персональных данных',
        },
      };
    }

    const isPhone = isAtLeastPartialPhone(model.login);
    const data = {
      inn: model.inn,
      [isPhone ? 'user_phone' : 'email']: (
        isPhone
          ? model.login.replace(/[^\d]/g, '').replace(/^8/, '7')
          : model.login
      ),
      captcha: model.captcha,
      ...(model.referrer_id ? { referrer_id: model.referrer_id } : {}),
    };

    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.signUp,
      data,
    } as ApiRequest<SignUpModel>, { root: true })) as ApiResponse<SignUpResponse>;

    if (status) {
      commit('setCodeUntil', new Date((new Date()).getTime() + 60 * 1000));
      commit('setRegistrationData', data);
    }

    return {
      status,
      response,
    } as ApiResponse<SignUpResponse>;
  },
  async verify({ dispatch, getters, commit }, model: VerifyModel) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.verify,
      data: {
        ...getters.registrationData,
        ...model,
      },
    } as ApiRequest<VerifyModel>, { root: true })) as ApiResponse<VerifyResponse>;

    if (status) {
      commit('setRegistrationData', {
        ...getters.registrationData,
        verification_code: model.verification_code,
      });
    }

    return {
      status,
      response,
    } as ApiResponse<VerifyResponse>;
  },
  async restore({ dispatch, commit }, model: RestoreModel) {
    const isPhone = isAtLeastPartialPhone(model.login);
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.restore,
      data: {
        [isPhone ? 'user_phone' : 'email']: (
          isPhone
            ? model.login.replace(/[^\d]/g, '').replace(/^8/, '7')
            : model.login
        ),
        ...(model.captcha ? { captcha: model.captcha } : {}),
      },
    } as ApiRequest<RestoreModel>, { root: true })) as ApiResponse<RestoreResponse>;

    if (status) {
      commit('setCodeUntil', new Date((new Date()).getTime() + 60 * 1000));
      commit('setRegistrationData', {
        [isPhone ? 'user_phone' : 'email']: model.login,
      });
    }

    return {
      status,
      response,
    } as ApiResponse<RestoreResponse>;
  },
  /**
   * last step of registration or recover / change password
   */
  async setPassword({ dispatch, getters, commit }, model: PasswordModel) {
    if (model.passwordConfirmation !== model.password) {
      return {
        status: false,
        response: {
          passwordConfirmation: 'Пароли не совпадают',
        },
      };
    }
    const data = {
      ...getters.registrationData,
      ...model,
      user_role: UserRole.company,
      user_inn: parseInt(getters.registrationData.inn, 10),
    };
    if (!model.refererId) {
      delete data.referrer_id;
    }
    const { status, response } = (await dispatch('api/request', {
      command: getters.registrationData.inn
        ? ApiCommand.doneSignUp
        : ApiCommand.doneRestore,
      data,
    } as ApiRequest<PasswordModel>, {
      root: true,
    })) as ApiResponse<PasswordResponse & SignInResponse>;

    return {
      status,
      response,
    } as ApiResponse<PasswordResponse>;
  },
  async checkoutToUser({ dispatch, state, commit }, user_id: number) {
    const refresh = state.token?.refresh.value;
    if (!refresh) {
      console.error('cannot checkout to user: no refresh token');
      return;
    }

    const checkoutResponse = await commonLegacyApiRequest<{ refresh: string; access: string; user_id: number }>({
      command: ApiCommand.checkoutToUser,
      data: {
        refresh,
        user_id,
      },
    });

    if (!checkoutResponse.status) {
      console.error(`cannot checkout to user: ${checkoutResponse.response}`);
      return;
    }

    commit('setCanCheckoutBack', true);
    await dispatch('onSignIn', checkoutResponse.response);
    // window.location.reload();
  },
  async checkoutBack({ dispatch, state, commit }) {
    if (!state.canCheckoutBack || !state.token) {
      return;
    }
    if (jwtTokenIsExpired(state.token.refresh)) {
      throw new Error('refresh token expired');
    }

    commit('setTokenIsRefreshing', true);
    refreshingPromise = new Promise<void>((resolve, reject) => {
      resolveRefreshingPromise = resolve;
      rejectRefreshingPromise = reject;
    });
    const result = await AuthService.refresh(state.token.refresh.value)();
    if (E.isLeft(result)) {
      // @ts-ignore
      if (rejectRefreshingPromise === undefined) {
        dispatch('signOut');
        console.error('getJwtTokens error: no rejectRefreshingPromise');
        return E.left(O.none);
      }
      rejectRefreshingPromise?.(new Error('rejected'));
      throw new Error('rejected');
    }
    commit('setTokenIsRefreshing', false);
    // @ts-ignore
    resolveRefreshingPromise();

    await dispatch('signOut');
    await dispatch('onSignIn', result.right);
  },

  async onSignIn({ dispatch, commit }, model: AuthResponseSuccess) {
    const accessTokenData = parseJwtToken(model.access);
    const refreshTokenData = parseJwtToken(model.refresh);
    if (!accessTokenData || !refreshTokenData) {
      return;
    }
    await dispatch('layout/showPreloader', null, { root: true });

    const fiveMinutesInMs = 5 * 60 * 1000;
    const newTimestamp = accessTokenData.exp - fiveMinutesInMs;

    commit('setToken', {
      access: {
        value: model.access,
        exp: String(newTimestamp),
      },
      refresh: {
        value: model.refresh,
        exp: String(refreshTokenData.exp),
      },
    });
    commit('setData', {
      id: accessTokenData.user_id,
      is_demo: false,
    });
  },
  // async signIn({ dispatch, commit }, model: SignInModel) {
  //   // @TODO вынести
  //   if (!model.agreement) {
  //     return {
  //       status: false,
  //       response: {
  //         agreement: 'Подтвердите обработку персональных данных',
  //       },
  //     };
  //   }
  //   const isPhone = model.user_login && isAtLeastPartialPhone(model.user_login!);
  //   const { status, response } = (await dispatch('api/request', {
  //     command: ApiCommand.signIn,
  //     data: {
  //       ...model,
  //       user_login: model.user_login ? isPhone
  //         ? model.user_login.replace(/[^\d]/g, '').replace(/^8/, '7')
  //         : model.user_login : undefined,
  //     },
  //   } as ApiRequest<SignInResponse>, {
  //     root: true,
  //   })) as ApiResponse<SignInResponse>;
  //
  //   if (status) {
  //     await dispatch('layout/showPreloader', null, { root: true });
  //     setTimeout(() => {
  //       commit('setToken', {
  //         token: response.auth_token,
  //         validUntil: new Date(response.valid_till),
  //       } as UserToken);
  //       commit('setData', {
  //         id: response.id,
  //         is_demo: response.demo,
  //       });
  //     }, 1000);
  //   }
  //
  //   return {
  //     status,
  //     response,
  //   } as ApiResponse<SignInResponse>;
  // },
  async signOut({ dispatch, commit }) {
    commit('setToken', null);
    commit('setData', null);
    commit('setTokenIsRefreshing', false);
    commit('setCriticalDataIsLoaded', false);
    commit('setCanCheckoutBack', false);
    dispatch('tasksProgress/clearTasks', null, { root: true });

    return {
      status: true,
      response: true,
    } as ApiResponse<boolean>;
  },
  async changePassword({ dispatch, getters }, model: ChangePasswordModel) {
    if (model.password !== model.confirmation) {
      return {
        status: false,
        response: {
          // @TODO первести на бэк
          confirmation: 'Пароли не совпадают',
        },
      } as ApiResponse<ChangePasswordResponse>;
    }
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.changePassword,
      params: {
        id: (getters.data as UserData).id,
      },
      data: model,
    } as ApiRequest, {
      root: true,
    })) as ApiResponse<ChangePasswordResponse>;

    return {
      status,
      response,
    } as ApiResponse<ChangePasswordResponse>;
  },
  async checkAuth(context, {
    isAuthorized,
    guestRequired,
    authRequired,
  }: AuthGuard): Promise<AuthGuardResponse> {
    if (isAuthorized /* && (getters.token.validUntil > new Date()) */) {
      if (guestRequired) {
        return { result: false, redirect: { name: 'index' } };
      }
    } else if (authRequired) {
      return { result: false, redirect: { name: 'sign' } };
    }
    return { result: true };
  },
  async getJwtTokens({ state, dispatch, commit }) {
    try {
      if (state.token === null) {
        dispatch('signOut');
        return E.left(O.none);
      }

      if (state.tokenIsRefreshing) {
        // @ts-ignore
        await (refreshingPromise as Promise<void>);
      }

      if (jwtTokenIsExpired(state.token.access)) {
        if (jwtTokenIsExpired(state.token.refresh)) {
          throw new Error('refresh token expired');
        }
        commit('setTokenIsRefreshing', true);
        refreshingPromise = new Promise<void>((resolve, reject) => {
          resolveRefreshingPromise = resolve;
          rejectRefreshingPromise = reject;
        });
        const result = await AuthService.refresh(state.token.refresh.value)();
        if (E.isLeft(result)) {
          // @ts-ignore
          if (rejectRefreshingPromise === undefined) {
            dispatch('signOut');
            console.error('getJwtTokens error: no rejectRefreshingPromise');
            return E.left(O.none);
          }
          rejectRefreshingPromise?.(new Error('rejected'));
          throw new Error('rejected');
        }
        const accessData = jwtContentToUserStore(result.right.access, parseJwtToken(result.right.access) as JwtContent);
        const refreshData = jwtContentToUserStore(result.right.refresh, parseJwtToken(result.right.refresh) as JwtContent);
        commit('setToken', {
          access: accessData,
          refresh: refreshData,
        });
        commit('setTokenIsRefreshing', false);
        // @ts-ignore
        resolveRefreshingPromise();
      }

      if (!state.token) {
        throw new Error('unexpected no tokens');
      }

      return E.right(state.token);
    } catch (e) {
      dispatch('signOut');
      console.error(`getJwtTokens error: ${e}`);
      return E.left(O.none);
    }
  },
};

export const plugins: Array<Plugin<StoreState>> = [
  // (store) => {
  //   return;
  //   let signOutTimeout: number;
  //   store.watch((state) => state.user.token?.validUntil, (validUntil) => {
  //     clearTimeout(signOutTimeout);
  //     if (validUntil) {
  //       signOutTimeout = setTimeout(async () => {
  //         await store.dispatch('user/signOut');
  //       }, validUntil.getTime() - Date.now());
  //     }
  //   }, {
  //     immediate: true,
  //   });
  // },
  (store) => {
    store.watch((state, getters) => getters['user/isAuthorized'], async (isAuthorized) => {
      if (!isAuthorized) {
        store.commit('user/setData', null);
      } else {
        await store.dispatch('user/fetchData');
      }
    }, {
      immediate: true,
    });
  },
  // FIX
  (store) => {
    store.watch((state) => state.user.data, (user) => {
      if (!user) {
        store.commit('user/setToken', null);
      }
    }, { immediate: true });
  },
  (store) => {
    store.subscribe(async ({ type, payload }) => {
      if (type === 'layout/signal' && [
        SignalType.companyAdded,
        SignalType.companyUpdated,
        SignalType.companyDeleted,
      ].includes(payload.signalType)) {
        const { status, response } = (
          await store.dispatch('companies/fetchCompanies')
        ) as ApiResponse<FetchCompaniesResponse>;

        if (status) {
          store.commit('companies/setCompanies', response.results);
        }

        await store.dispatch('companies/fetchDefaultCompanyId');
      }
    });

    store.watch((state) => state.user.criticalDataIsLoaded, (v) => {
      if (v) {
        store.commit(
          'layout/signal',
          { signalType: SignalType.criticalDataIsLoaded },
        );
      }
    });

    store.watch((state) => state.user.data?.id, async (id) => {
      await awaitFrame();

      if (!id) {
        store.commit('companies/setCompanies', []);
        store.commit('companies/setDefaultCompanyId', null);
        store.commit('user/setCodeUntil', null);
        await Promise.all([
          store.dispatch('layout/closeDialogs'),
          store.dispatch('layout/closeToasts'),
        ]);
        return;
      }
      const { status, response } = (
        await store.dispatch('companies/fetchCompanies')
      ) as ApiResponse<FetchCompaniesResponse>;

      await store.dispatch('companies/fetchDefaultCompanyLogo');

      if (status) {
        store.commit('companies/setCompanies', response.results);
      }

      await store.dispatch('companies/fetchDefaultCompanyId');

      if (!store.getters['companies/defaultCompanyId']) {
        const { id } = store.getters['companies/companies'][0] ?? {};
        if (!id) {
          store.commit('user/setCriticalDataIsLoaded', true);
          return;
        }
        await Promise.all([
          store.dispatch('companies/fetchDefaultCompanyACL'),
        ]);
        store.commit('companies/setDefaultCompanyId', id);
        await store.dispatch('companies/setDefaultCompanyId', id);
        store.commit('user/setCriticalDataIsLoaded', true);
        store.commit(
          'layout/signal',
          { signalType: SignalType.criticalDataIsLoaded },
        );
      } else {
        await Promise.all([
          store.dispatch('companies/fetchDefaultCompanyACL'),
        ]);
        store.commit('user/setCriticalDataIsLoaded', true);
      }
    }, {
      immediate: true,
    });
  },
  (store) => {
    if (!envIsMobile) {
      window.addEventListener('storage', (event) => {
        const { key, newValue, oldValue } = event;
        if (key === 'store') {
          const newStore = JSON.parse(newValue || '{}') as any;
          const oldStore = JSON.parse(oldValue || '{}') as any;
          if (
            oldStore?.user?.tokenIsRefreshing === false
            && newStore?.user?.tokenIsRefreshing === true
          ) {
            store.commit('user/setTokenIsRefreshing', true);
            refreshingPromise = new Promise<void>((resolve, reject) => {
              resolveRefreshingPromise = resolve;
              rejectRefreshingPromise = reject;
            });
          }

          if (
            oldStore?.user?.tokenIsRefreshing === true
            && newStore?.user?.tokenIsRefreshing === false
          ) {
            if (!newStore.user.token) {
              rejectRefreshingPromise(new Error('get jwt tokens rejected'));
            } else {
              store.commit('user/setToken', newStore.user.token);
              store.commit('user/setTokenIsRefreshing', false);
              resolveRefreshingPromise();
            }
          }
        }
      });
    }
  },
];
