import { computed, Ref } from 'vue';
import { ITab } from '@/components/tabs/useTabs';
import { ActiveTabKey } from '@/components/dialog/dialogs/debtor/tabs/documents/types';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { ProductionType } from '@/hooks/useConstructor';

export const useTabKeys = (productionType: Ref<ProductionType>) => {
  const { companyModuleType } = useDefaultCompany();

  const vehicles = companyModuleType.value === CompanyModuleType.Vehicles;
  const judicial = productionType.value === ProductionType.judicial;
  const pretrial = productionType.value === ProductionType.pretrial;
  const executive = productionType.value === ProductionType.executive;

  const executiveTabs: Array<{ key: ActiveTabKey }> = [
    { key: 'common' },
    { key: 'identity' },
    { key: 'inn_identity' },
    { key: 'bc_identity' },
    { key: 'judgments' },
    { key: 'fssp' },
    { key: 'fns' },
    { key: 'banks' },
    { key: 'fee' },
    { key: 'my_documents' },
    { key: 'pochta' },
  ];

  return computed(() => ((executive ? executiveTabs : [
    {
      key: 'common',
    },
    {
      key: 'identity',
    },
    { key: 'inn_identity' },
    { key: 'bc_identity' },
    vehicles && {
      key: 'photos',
    },
    judicial && !vehicles && {
      key: 'housebook',
    },
    judicial && !vehicles && {
      key: 'egrn_data',
    },
    judicial && !vehicles && {
      key: 'egrn_rights',
    },
    judicial && {
      key: 'fee',
    },
    judicial && !vehicles && {
      key: 'protocol_ooc',
    },
    {
      key: 'preloaded_charges',
    },
    {
      key: 'claims',
    },
    {
      key: 'preloaded_penny',
    },
    {
      key: 'guarantee_letter',
    },
    {
      key: 'act',
    },
    companyModuleType.value === 4 && {
      key: 'loan_agreement',
      label: 'Кредитный договор',
    },
    {
      key: 'agreements',
      label: companyModuleType.value === 4 ? 'Договор цессии' : 'Договор',
    },
    !pretrial && {
      key: 'judgments',
    },
    pretrial && {
      key: 'sms',
    },
    pretrial && {
      key: 'voice',
    },
    {
      key: 'my_documents',
    },
    {
      key: 'pochta',
    },
    {
      key: 'invoice',
    },
    {
      key: 'returnGP',
    },
    {
      key: 'submit_to_court',
    },
    {
      key: 'settlement_statements',
    },
    {
      key: 'technical_passport',
    },
    {
      key: 'inspection_report',
    },
  ]) as Array<ITab<ActiveTabKey> | boolean>)
    .filter(Boolean) as Array<ITab<ActiveTabKey>>);
};
