import { parseDateStringWithDefaultFormat } from '@/components/dateInputNext/utils';
import { TenantBankruptData } from '@/hooks/useDebtors';

type HasData = {
  bankrupt_data?: TenantBankruptData[];
  bankrupts_data?: TenantBankruptData[];
}

export function getBankruptLabel(item: HasData) {
  const array = item.bankrupts_data ?? item.bankrupt_data ?? [];
  return array.map((data) => {
    const parse = parseDateStringWithDefaultFormat;
    const hasKad = data.sources.indexOf('kad') !== -1;
    const hasEfrsb = data.sources.indexOf('efrsb') !== -1;
    const first = hasEfrsb ? 'является банкротом:\n' : (hasKad ? 'начата процедура банкротства:\n' : '');
    const second = `№${data.case_number}\n`;
    const kad = hasKad ? `КАД от ${parse(data.accept_statement_date)}\n` : '';
    const efrsb = hasEfrsb ? `ЕФРСБ от ${parse(data.decision_date)}\n` : '';
    const decision = data.decision_date ? `Дата признания банкротом:\n${parse(data.decision_date)}\n` : '';
    const sellProp = data.realization_property_date ? `Реализация имущества:\n${parse(data.realization_property_date)}\n` : '';
    const complDate = data.bankruptcy_completion_date ? `Дата завершения банкротства:\n${parse(data.bankruptcy_completion_date)}\n` : '';
    return `Должник ${data.tenant_name} - ${[first, second, kad, efrsb, decision, sellProp, complDate].join('')}`;
  }).join('\n');
}
