import { useStore } from 'vuex';
import { Company } from '@/hooks/useCompanies';
import {
  ApiResponse,
  ListingRequestSource,
  ListingResponse,
} from '@/store/modules/api';
import { ProductionType } from '@/hooks/useConstructor';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { Employee } from '@/hooks/useEmployees';
import { IToastLevel, useToast } from '@/hooks/useToast';

export enum AccountDocumentClass {
  default = 'default',
  attorney = 'attorney',
  attorney_sig = 'attorney_sig',
  document_sig = 'document_sig',
  ifns_vypis = 'ifns_vypis',
  image = '21',
  document_of_education = 'document_of_education',
  document_of_marriage_or_change_of_name = 'document_of_marriage_or_change_of_name',
}

export type AccountDocument = {
  id: number | null;
  name: string;
  file: string;
  description: string;
  yandex_url: string;
  can_be_attached: boolean;
  klass: AccountDocumentClass;
  signer: string;
  signer_name: string;
  company: Company['id'];
  readable_path: boolean;
  crypto_pro_certificate: any;
  // crypto_pro_certificate: CertificateResponseModel | number | null;
  production_type: ProductionType;
  mark?: number;
  sig: number|null; // ID привязки документа-подписи
  document: number | null;
  users: Array<Employee['id']>; // id у employee и user одинаковые
  debtor_type: string|null;
  certificate?: Array<Certificate>;
  certificates?: Array<CertificateResponseModel>;
  certificateOptions?: Array<CertificateModel>;
  // auto_sign_certificate: boolean;
}

export type CertificateModel = {
  id: string;
  name: string;
  raw: CryptoDocument;
}

export type CertificateResponseModel = {
  id: number;
  name: string;
  company: number;
}

export type Certificate = {
  name: string;
  raw: CryptoDocument;
  percentage: number;
  size: number;
  status: string;
  uid: number;
}

export type CryptoDocument = File;

export type FetchAccountDocumentsModel = ListingRequestSource<AccountDocument>;

export type FetchAccountDocumentsResponse = ListingResponse<AccountDocument>;

export type CreateAccountDocumentModel = {
  company_id: Company['id'];
  payload: AccountDocument;
};

export type CreateCryptoDocumentModel = {
  file: CryptoDocument;
  name: string;
  company: Company['id'];
};

export type PatchCryptoDocumentModel = {
  file: CryptoDocument;
  name: string;
  id: number;
};

export type SignCryptoDocumentModel = {
  id: number;
};
export type CreateCryptoDocumentResponse = CryptoDocument;

export type CreateAccountDocumentResponse = AccountDocument;

export type UpdateAccountDocumentModel = {
  id: AccountDocument['id'];
  company_id: Company['id'];
  payload: AccountDocument;
};
export type UpdateAccountDocumentResponse = AccountDocument;

export type RemoveAccountDocumentModel = {
  company_id: Company['id'];
  id: AccountDocument['id'];
};
export type RemoveAccountDocumentResponse = null;

export type CreateCryptoProCertificateResponse = {
  id: number;
  name: string;
  company: number;
};

export const useAccountDocuments = () => {
  const store = useStore();
  const {
    companyId,
  } = useProtectedDefaultCompany();

  const fetchAccountDocuments = async (
    request?: FetchAccountDocumentsModel,
  ): Promise<ApiResponse<FetchAccountDocumentsResponse>> => {
    const { status, response } = await store.dispatch('accountDocuments/fetchAccountDocuments', request);

    return { status, response };
  };

  const createAccountDocument = async (
    request: CreateAccountDocumentModel,
  ): Promise<ApiResponse<CreateAccountDocumentResponse>> => {
    const { status, response } = await store.dispatch('accountDocuments/createAccountDocument', request);

    return { status, response };
  };

  const updateAccountDocument = async (
    request: UpdateAccountDocumentModel,
  ): Promise<ApiResponse<UpdateAccountDocumentResponse>> => {
    const { status, response } = await store.dispatch('accountDocuments/updateAccountDocument', request);

    return { status, response };
  };

  const removeAccountDocument = async (
    request: RemoveAccountDocumentModel,
  ): Promise<ApiResponse<RemoveAccountDocumentResponse>> => {
    const { status, response } = await store.dispatch('accountDocuments/removeAccountDocument', request);

    return { status, response };
  };

  return {
    fetchAccountDocuments,
    createAccountDocument,
    updateAccountDocument,
    removeAccountDocument,
  };
};

export const useCryptoProCertificate = () => {
  const { showToast } = useToast();
  const store = useStore();
  const cryptoProCertificateSign = async (
    request: { id: number },
  ) => {
    const { status, response } = await store.dispatch('accountDocuments/cryptoProCertificateSign', request);

    return { status, response };
  };
  const deleteCryptoProCertificate = async (
    request: { id: number },
  ) => {
    const { status, response } = await store.dispatch('accountDocuments/deleteCryptoProCertificate', request);

    return { status, response };
  };
  const patchCryptoProCertificate = async (
    request: { file: File; name: string; id: number },
  ) => {
    const { status, response } = await store.dispatch('accountDocuments/patchCryptoProCertificate', request);

    return { status, response };
  };

  const getCryptoProCertificate = async (
    request?: FetchAccountDocumentsModel,
  ) => {
    const { status, response } = await store.dispatch('accountDocuments/getCryptoProCertificate', request);

    return { status, response };
  };

  const createCryptoProCertificate = async (
    request: CreateCryptoDocumentModel,
  ): Promise<ApiResponse<CreateCryptoProCertificateResponse>> => {
    const { status, response } = await store.dispatch('accountDocuments/createCryptoProCertificate', request);

    if (!status) {
      await showToast({
        level: IToastLevel.danger,
        label: 'pureLabel',
        params: {
          label: 'Файл ЭЦП уже загружен',
        },
      });
    } else {

    }
    return { status, response };
  };

  return {
    createCryptoProCertificate,
    getCryptoProCertificate,
    cryptoProCertificateSign,
    deleteCryptoProCertificate,
    patchCryptoProCertificate,
  };
};

export const getEmptyAccountDocument = (
  companyId: Company['id'],
  documentClass: AccountDocumentClass = AccountDocumentClass.default,
  signerId: number | null = null,
): AccountDocument => ({
  id: null,
  name: '',
  file: '',
  description: '',
  yandex_url: '',
  crypto_pro_certificate: null,
  can_be_attached: documentClass !== AccountDocumentClass.attorney
    && documentClass !== AccountDocumentClass.attorney_sig
    && documentClass !== AccountDocumentClass.document_sig,
  klass: documentClass,
  signer: '',
  signer_name: '',
  company: companyId,
  readable_path: false,
  production_type: ProductionType.judicial,
  sig: null,
  document: signerId,
  users: [],
  debtor_type: null,
  certificateOptions: [],
  certificates: [],
  // auto_sign_certificate: false,
});
