import { Module, Plugin } from 'vuex';
import {
  DataUploadTemplate, Dict, DictMap, DictType,
} from '@/hooks/useDicts';
import { StoreState } from '@/store';
import { t } from '@/i18n';
import {
  ApiCommand,
  ApiRequest,
  ApiResponse,
  ListingRequest,
  ListingRequestSource, ListingResponse,
} from '@/store/modules/api';
import { Company } from '@/hooks/useCompanies';
import { awaitFrame } from '@/utils/window';
import { EmployeeEventNotifications, EmployeeRole } from '@/hooks/useEmployees';
import { formatListingResponse } from '@/components/activeTable/useActiveTable';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { envIsMobile } from '@/utils/env';
import { DebtorStatus } from '@/hooks/useDebtors';
import { unwrapListingApiResponse } from '@/service/api';
import { logArray } from '@/utils/log-array';

export type DictsState = {
  dicts: {
    [key in DictType]: Dict<any>;
  };
}

type DictModule = Module<DictsState, StoreState>;

export const namespaced = true;
export const state: DictModule['state'] = () => ({
  dicts: {
    [DictType.smsServices]: [
      {
        value: '320d53db-abc4-4035-81d0-56f99c481e85',
        label: 'Sendpulse',
      },
      {
        value: '6f76c469-be6c-44e7-a0c9-ce2b8b4f2962',
        label: 'SmsC',
      },
    ],
    [DictType.debtorStatuses]: [],
    [DictType.debtorSubstatuses]: [],
    [DictType.debtorStatusesForFilters]: [],
    [DictType.debtorSubstatusesForFilters]: [],
    [DictType.companyServices]: [],
    [DictType.companyDebtorStatuses]: [],
    [DictType.companyDebtorSubstatuses]: [],
    [DictType.companyModuleType]: [
      {
        value: 1,
      },
      {
        value: 2,
      },
      {
        value: 3,
      },
      {
        value: 4,
      },
    ],
    [DictType.companyTaxationSystems]: [
      {
        value: 'osn',
      },
      {
        value: 'usn',
      },
    ],
    [DictType.companyPeniCalculationTypes]: [
      {
        value: '155',
      },
      {
        value: '155_14_1',
      },
      {
        value: '395',
      },
    ],
    [DictType.penaltyKeyRates]: [
      {
        value: 'k_rate',
      }, {
        value: 'p_pay',
      }, {
        value: 'court',
      }, {
        value: 'p_pen',
      },
    ],
    [DictType.employeeEventNotifications]: [
      {
        value: EmployeeEventNotifications.debtor_documents,
      },
      {
        value: EmployeeEventNotifications.debtor_data_registry,
      },
      {
        value: EmployeeEventNotifications.debtor_report,
      },
      {
        value: EmployeeEventNotifications.debtor_court_decision_report,
      },
    ],
    [DictType.employeePositions]: [],
    [DictType.employeeRoles]: [
      // EmployeeRole.admin,
      // EmployeeRole.owner,
      EmployeeRole.manager,
      EmployeeRole.employee,
      EmployeeRole.guest,
    ].map((value) => ({
      value,
    })),
    [DictType.files]: [
      {
        value: 'agreement',
        label: envIsMobile ? 'https://app.urrobot.tech/files/documents/privacy_policy.pdf' : '/files/documents/EULA.pdf',
      },
      {
        value: 'policy',
        label: envIsMobile ? 'https://app.urrobot.tech/files/documents/EULA.pdf' : '/files/documents/privacy_policy.pdf',
      },
    ],
    [DictType.tenantRelationships]: [],
    [DictType.dataUploadTemplates]: [],
    [DictType.baseTypeOptions]: [
      { value: 'Смешанный договор' },
      { value: 'Трудовой договор' },
      { value: 'Договор ренты' },
      { value: 'Договор страхования' },
      { value: 'Налоги, сборы и иные взыскания' },
      { value: 'Акт государственного органа' },
      { value: 'Договор энергоснабжения' },
      { value: 'Договор лизинга' },
      { value: 'Внешнеторговый договор' },
      { value: 'Договор оказания услуг' },
      { value: 'Договор дарения' },
      { value: 'Договор хранения' },
      { value: 'Договор аренды' },
      { value: 'Договор перевозки' },
      { value: 'Договор авторского заказа' },
      { value: 'Лицензионный договор' },
      { value: 'Договор об отчуждении исключительного права' },
      { value: 'Кредитный договор' },
      { value: 'Договор поставки' },
      { value: 'Иной договор' },
      { value: 'Договор купли-продажи' },
      { value: 'Договор подряда' },
    ],
    [DictType.complaintTypes]: [],
  },
});

export const getters: DictModule['getters'] = {

  dicts: (
    state, getters, rootState, rootGetters,
  ) => {
    const isVehicle = rootGetters['companies/defaultCompany']?.module === CompanyModuleType.Vehicles;
    return Object.entries(state.dicts).reduce((acc, [key, items]) => {
      // в фильтрах автодора не должно быть статусов связанных с заказом выписки из ЕГРН и "пошлина ожидает оплаты по дольщикам
      // @ts-ignore
      acc[key] = key !== DictType.debtorSubstatuses
        ? items.filter(({ group, value }) => !group || (!isVehicle ? true : (group !== 'statement' && value !== 'fees_shareholders_await_paid')))
        : [...items];
      return acc;
    }, {} as DictsState['dicts']);
  },

  getDict: (
    state, getters,
  ) => (type: DictType) => getters.dicts[type]
    .map((item: any) => ({
      ...item,
      label: item.label || item.name || t(`dict.${type}.${item.value}`),
    })),

  dictsMap: (
    state, getters, rootState, rootGetters,
  ) => Object.entries(getters.dicts).reduce((acc, [dictKey, items]) => {
    // @ts-ignore
    acc[dictKey] = items
      .reduce((acc: any, item: any) => {
        acc[item.value] = item.label || item.name || t(`dict.${dictKey}.${item.value}`);
        return acc;
      }, {} as Record<string, {label: string}>);
    return acc;
  }, {}) as DictsState['dicts'],

  getDictMap: (state, getters) => (
    type: DictType,
    valueField: any = 'value',
    labelField: any = 'label',
  ): DictMap<any, any> => (getters.getDict(type) as Dict<any>).reduce(
    (acc, cur) => {
      acc[cur[valueField]] = cur[labelField];
      return acc;
    }, {},
  ),
};

export const mutations: DictModule['mutations'] = {
  setDict: (state, payload: {type: DictType; dict: Dict<any>}) => {
    state.dicts = {
      ...state.dicts,
      [payload.type]: payload.dict,
    };
  },
};

export const actions: DictModule['actions'] = {
  async fetchDicts({ commit, dispatch, rootGetters }) {
    await Promise.all([
      rootGetters['companies/defaultCompanyId'] && new Promise(async (resolve) => {
        const {
          status,
          response,
        } = await dispatch('fetchEmployeePositions', rootGetters['companies/defaultCompanyId']);
        if (status) {
          commit('setDict', {
            type: DictType.employeePositions,
            dict: response,
          });
        }
        resolve(true);
      }),
      new Promise(async (resolve) => {
        await dispatch('setTenantRelationships');
        resolve(true);
      }),
      dispatch('fetchCompanyDebtorStatuses'),
      new Promise(async (resolve) => {
        const {
          status,
          response,
        } = (await dispatch('api/request', {
          command: ApiCommand.fetchDebtorStatuses,
        } as ApiRequest, { root: true })) as ApiResponse<{
          statuses: Array<{ info: string; value: string }>;
          substatuses: Array<{ info: string; value: string }>;
        }>;

        if (status) {
          commit('setDict', {
            type: DictType.debtorStatuses,
            dict: [
              ...response.statuses.map((status) => ({
                ...status,
                value: status.value,
                label: status.info,
              })),
              {
                value: 'error',
                label: 'Ошибка',
              },
            ],
          });
          commit('setDict', {
            type: DictType.debtorSubstatuses,
            dict: response.substatuses.map((status) => ({
              ...status,
              value: status.value,
              label: status.info,
            })),
          });
        }
        resolve(true);
      }),
      new Promise(async (resolve) => {
        const {
          status,
          response,
        } = (await dispatch('api/request', {
          command: ApiCommand.fetchDebtorStatusesForFilters,
        } as ApiRequest, { root: true })) as ApiResponse<{
          statuses: Array<{ info: string; value: string }>;
          substatuses: Array<{ info: string; value: string }>;
        }>;

        if (status) {
          commit('setDict', {
            type: DictType.debtorStatusesForFilters,
            dict: [
              ...response.statuses.map((status) => ({
                ...status,
                value: status.value,
                label: status.info,
              })),
            ],
          });
          commit('setDict', {
            type: DictType.debtorSubstatusesForFilters,
            dict: response.substatuses.map((status) => ({
              ...status,
              value: status.value,
              label: status.info,
            })),
          });
        }
        resolve(true);
      }),
      dispatch('fetchCompanyServices'),
      dispatch('fetchConstructorDataUploadTemplates'),
      dispatch('fetchComplainTypes'),
    ].filter(Boolean));
  },
  async fetchCompanyServices({ commit, dispatch }) {
    return dispatch('api/request', {
      command: ApiCommand.getCompanyServices,
    }, { root: true }).then(
      ({ status, response }: ApiResponse<ListingResponse<{name: string; id: number}>>) => {
        if (status) {
          commit('setDict', {
            type: DictType.companyServices,
            dict: response.results.map(({ name, id }) => ({
              value: name, label: name,
            })),
          });
        }
      },
    );
  },
  async fetchEmployeePositions({ dispatch, commit }) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.getAccountPosition,
      params: {
        limit: 1000,
      },
    } as ApiRequest, { root: true })) as ApiResponse<ListingResponse<{
      name: string; id: number;
    }>>;

    if (status) {
      commit('setDict', {
        type: DictType.employeePositions,
        dict: response.results.map(({ name, id }) => ({
          value: id, label: name,
        })),
      });
    }

    return {
      status,
      response: response?.results || [],
    };
  },
  async setTenantRelationships({ dispatch, commit }, id: Company['id']) {
    commit('setDict', {
      type: DictType.tenantRelationships,
      dict: Object.entries({
        son: 'Сын',
        daughter: 'Дочь',
        sister: 'Сестра',
        wife: 'Жена',
        husband: 'Муж',
        mother: 'Мать',
        father: 'Отец',
        other: 'Другое',
      }).reduce((acc, [value, label]) => ([
        ...acc,
        { value, label },
      ]), [] as Dict<any>),
    });
  },
  // @TODO сделать нормальный GET
  async fetchBanks({ dispatch }, { limit, page, filters }: ListingRequestSource<{ bik: string }>) {
    const { status, response } = (await dispatch('api/request', {
      command: ApiCommand.lookupBik,
      params: {
        limit,
        page,
      },
      data: {
        bik: filters?.bik,
      },
    } as ApiRequest<ListingRequest<{bik: string}>>, {
      root: true,
    })) as ApiResponse<Array<{kor_schet: string}>>;

    return {
      status,
      response: formatListingResponse<{kor_schet: string}>({
        count: response.length,
        results: response,
      }),
    };
  },

  async fetchInns({ dispatch }, { inn }: { inn: string }) {
    return (await dispatch('api/request', {
      command: ApiCommand.lookupInn,
      data: {
        inn,
      },
    }, {
      root: true,
    })) as ApiResponse<{value: string}>;
  },
  async fetchConstructorDataUploadTemplates({ commit, dispatch }) {
    const { status, response } = await dispatch('api/request', {
      command: ApiCommand.fetchConstructorDataUploadTemplates,
    }, { root: true }) as ApiResponse<ListingResponse<DataUploadTemplate>>;

    if (status) {
      commit('setDict', { type: DictType.dataUploadTemplates, dict: response.results });
    }
  },
  async fetchCompanyDebtorStatuses({
    commit, dispatch, getters, rootGetters,
  }) {
    const companyId = rootGetters['companies/defaultCompanyId'];
    if (!companyId) {
      return;
    }
    const [statusesResponse, otherStatusesResponse] = await Promise.all([
      dispatch('api/request', {
        command: ApiCommand.companyDebtorStatusesFetchList,
        params: { company_id: companyId },
      }, { root: true }) as Promise<ApiResponse<{ statuses: any[]; substatuses: any[] }>>,
      (dispatch('api/request', {
        command: ApiCommand.companyDebtorStatusesForCustomStatusFetchList,
        params: { company: companyId, status: 'other' },
      }, { root: true }) as Promise<ApiResponse<ListingResponse<DebtorStatus>>>).then(unwrapListingApiResponse),
    ]);

    if (!statusesResponse.status || !otherStatusesResponse.status) {
      console.error(`Ошибка загрузки констант: список статусов ${statusesResponse.status} otherStatusesResponse.status`);
      return;
    }

    const substatusesPrepared = [
      ...statusesResponse.response.substatuses.map(
        (status) => ({ ...status, value: status.value, label: status.info }),
      ),
      ...otherStatusesResponse.response.reduce((acc, { production_type, substatus }) => {
        if (!substatus.length) {
          return acc;
        }
        const { substatus: substatusLabel, data } = substatus[0];
        if (acc.find(({ label }) => label === substatusLabel)) {
          return acc;
        }
        acc.push({
          label: substatusLabel,
          info: data,
          value: substatusLabel,
          group: 'other',
          status: 'other',
          production_type,
        });
        return acc;
      }, [] as any[]),
    ];
    console.log('dictionary: substatusesPrepared', logArray(substatusesPrepared));

    if (statusesResponse.status) {
      commit(
        'setDict',
        {
          type: DictType.companyDebtorStatuses,
          dict: [...statusesResponse.response.statuses.map(
            (status) => ({ ...status, value: status.value, label: status.info }),
          ), {
            value: 'error',
            label: 'Ошибка',
          }],
        },
      );
      commit('setDict', {
        type: DictType.companyDebtorSubstatuses,
        dict: substatusesPrepared,
      });
    }
  },
  async fetchComplainTypes({ commit, dispatch }) {
    return dispatch('api/request', {
      command: ApiCommand.fetchCommonLocales,
    }, { root: true }).then(
      ({ status, response }: ApiResponse<{ru: any}>) => {
        commit('setDict', {
          type: DictType.complaintTypes,
          dict: Object.entries(response.ru['debtor.courts.court_type']).map(([value, info]) => ({
            value, info, group: 'court', production_type: 'judicial', status: 'filed_in_court', label: info,
          })),
        });
      },
    );
  },
};

export const plugins: Array<Plugin<StoreState>> = [
  async (store) => {
    store.watch(
      ((state, getters) => [state.companies.defaultCompanyId, getters['user/isAuthorized']]),
      async (n, o) => {
        const [companyId, hasAuth] = n;
        const [oldCompanyId, hasAuthOld] = o || [];
        if (hasAuth) {
          if (companyId && companyId !== oldCompanyId) {
            await awaitFrame();
            store.commit('dicts/setDict', {
              type: DictType.employeePositions,
              dict: [],
            });
            await store.dispatch('dicts/fetchDicts');
          }
        }
      }, {
        immediate: true,
      },
    );
  },
];
