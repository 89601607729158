import { Module, Plugin } from 'vuex';
import { AppMessages, FetchMessagesModel } from '@/hooks/useMessages';
import { StoreState } from '@/store';
import { ApiResponse } from '@/service/api';
import {
  ApiCommand, ListingRequest, ListingResponse, OrderDirection,
} from '@/store/modules/api';
import { SocketSubscriber } from '@/store/modules/socket';
import { NotificationSocketMessage } from '@/types/socket';
import { formatListingRequest } from '@/components/activeTable/useActiveTable';

export type MessagesState = {
  messages: AppMessages[];
  isLoading: boolean;
  isLoaded: boolean;
}

type MessagesModule = Module<MessagesState, StoreState>;

export const state: MessagesModule['state'] = {
  messages: [],
  isLoading: true,
  isLoaded: false,
};

export const getters: MessagesModule['getters'] = {
  hasUnreadMessages: (state) => state.messages.some((n) => !n.is_read),
  notifications: (state) => state.messages,
};

export const namespaced = true;

export const mutations: MessagesModule['mutations'] = {
  setMessages: (state, messages: AppMessages[]) => {
    state.messages = messages;
    state.isLoading = false;
    state.isLoaded = true;
  },
  markAsReadMessage: (state, id: number) => {
    const notification = state.messages.find((el) => el.id === id);
    if (notification) {
      notification.is_read = true;
    }
  },
};

export const actions: MessagesModule['actions'] = {
  fetchPrintReport: async ({ commit, dispatch, rootGetters }, params: FetchMessagesModel) => {
    // const companyId = rootGetters['companies/defaultCompanyId'];
    const response: Promise<ApiResponse<ListingResponse<AppMessages>>> = await dispatch('api/request', {
      command: ApiCommand.fetchPrintReport,
      params: formatListingRequest({
        ...params,
        // filters: {
        //   company: companyId,
        // },
      }),
    }, { root: true });
    // @ts-ignore
    if (response.status) commit('setMessages', response.response.results);

    return response;
  },
  fetchPrintReportId: async ({ commit, dispatch }, id: number) => {
    const response: Promise<ApiResponse<ListingResponse<AppMessages>>> = await dispatch('api/request', {
      command: ApiCommand.fetchPrintReportId,
      params: { id },
    }, { root: true });
    // @ts-ignore
    if (response.status) commit('setMessages', response.response.results);

    return response;
  },
  markAsReadMessage: async ({ dispatch, commit }, id: number) => {
    commit('markAsReadMessage', id);
    return (await dispatch('api/request', {
      command: ApiCommand.readPrintReport,
      params: { id },
    }, { root: true }) as Promise<ApiResponse<ListingResponse<AppMessages>>>);
  },
  deleteReportMessages: async ({ dispatch, commit }, id: number) => {
    commit('deleteReportMessages', id);
    return (await dispatch('api/request', {
      command: ApiCommand.deletePrintReport,
      params: { id, is_read: true },
    }, { root: true }) as Promise<ApiResponse<ListingResponse<AppMessages>>>);
  },
};

export const plugins: Array<Plugin<StoreState>> = [
  (store) => {
    store.watch((store, getters) => getters['companies/defaultCompanyId'], (newCompany, oldCompany) => {
      if (newCompany) {
        store.dispatch('messages/fetchPrintReport');
      }
    }, { immediate: true });
  },
  // (store) => {
  //   store.dispatch('socket/subscribe', {
  //     condition: (payload) => payload.data?.event === 'send_notification',
  //     handler: (payload) => {
  //       const {
  //         id, title, content, created_at, company_id,
  //       } = payload.data;
  //       if (company_id && company_id !== store.getters['companies/defaultCompanyId']) {
  //         return;
  //       }
  //       store.commit('notifications/pushNotification', {
  //         id, title, content, created_at,
  //       });
  //     },
  //   } as SocketSubscriber<NotificationSocketMessage>);
  // },
];
