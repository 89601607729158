import { Module } from 'vuex';
import { StoreState } from '@/store';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';
import { ActiveFormModel } from '@/hooks/useActiveForm';
import { ApiCommand as ReportingApiCommand } from '@/service/api/reporting/apiCommand';
import { ApiCommandConfig, ApiMethod, ApiRequestGeneric } from '@/service/api-types';

export const namespaced = true;

export type ApiState = {

}

type ApiModule = Module<ApiState, StoreState>;

// @TODO поделить на домены после заполнения всеми ручками
export enum ApiCommand {
  version = 'version',
  fetchCommonLocales = 'fetchCommonLocales',
  fetchDebtorStatuses = 'fetchDebtorStatuses',
  fetchDebtorStatusesForFilters = 'fetchDebtorStatusesForFilters',
  fetchDebtorBorrowers = 'fetchDebtorBorrowers',
  fetchDebtorBorrower = 'fetchDebtorBorrower',
  updateDebtorBorrower = 'updateDebtorBorrower',
  createDebtorBorrower = 'createDebtorBorrower',
  removeDebtorBorrower = 'removeDebtorBorrower',
  signIn = 'signIn',
  signUp = 'signUp',
  doneSignUp = 'doneSignUp',
  signOut = 'signOut',
  fetchAuthData = 'fetchAuthData',
  verify = 'verify',
  restore = 'restore',
  attachmentsList = 'attachmentsList',
  addAttachmentsList = 'addAttachmentsList',
  attachmentsById = 'attachmentsById',
  attachmentsUpdateById = 'attachmentsUpdateById',
  attachmentsDeleteById = 'attachmentsDeleteById',
  getCompanyServices = 'getCompanyServices',
  getCompanyPositions = 'getCompanyPositions',
  addCompanyPositions = 'addCompanyPositions',
  getCompanyPositionsById = 'getCompanyPositionsById',
  updateCompanyPositionById = 'updateCompanyPositionById',
  deleteCompanyPosition = 'deleteCompanyPosition',
  getCompanyServicesByCompanyId = 'getCompanyServicesByCompanyId',
  addCompanyServicesByCompanyId = 'addCompanyServicesByCompanyId',
  getCompanyServicesByServicesId = 'getCompanyServicesByServicesId',
  updateCompanyServicesByServicesId = 'updateCompanyServicesByServicesId',
  deleteCompanyServicesByServicesId = 'deleteCompanyServicesByServicesId',
  getAccountLog = 'getAccountLog',
  deleteAccountLog = 'deleteAccountLog',
  getAccountLogById = 'getAccountLogById',
  getUsersList = 'getUsersList',
  getAccountPosition = 'getAccountPosition',
  addUser = 'addUser',
  getUserCompanyList = 'getUserCompanyList',
  assignCompanyToUser = 'assignCompanyToUser',
  getUserInterfaceSettings = 'getUserInterfaceSettings',
  updateUserInterfaceSettings = 'updateUserInterfaceSettings',
  getUserData = 'getUserData',
  updateUserData= 'updateUserData',
  deleteUser = 'deleteUser',
  getAllUsers = 'getAllUsers',
  getDataFileList = 'getDataFileList',
  getDataFileStatus = 'getDataFileStatus',
  getDataFilePackage = 'getDataFilePackage',
  addJudicialStatus = 'addJudicialStatus',
  applyMoveDebtor = 'applyMoveDebtor',
  moveDebtor = 'moveDebtor',
  doneRestore = 'doneRestore',
  changePassword = 'changePassword',
  fetchCompanies = 'fetchCompanies',
  fetchCompany = 'fetchCompany',
  fetchDefaultCompanyId = 'fetchDefaultCompanyId',
  setDefaultCompanyId = 'setDefaultCompanyId',
  addCompany = 'addCompany',
  updateCompany = 'updateCompany',
  fetchEmployees = 'fetchEmployees',
  fetchEmployee = 'fetchEmployee',
  resendEmployeePassword = 'resendEmployeePassword',
  removeCompany = 'removeCompany',
  removeEmployee = 'removeEmployee',
  updateEmployee = 'updateEmployee',
  createEmployee = 'createEmployee',
  fetchAccountDocuments = 'fetchAccountDocuments',
  removeAccountDocument = 'removeAccountDocument',
  updateAccountDocument = 'updateAccountDocument',
  createAccountDocument = 'createAccountDocument',
  fetchAccountImages = 'fetchAccountImages',
  removeAccountImage = 'removeAccountImage',
  updateAccountImage = 'updateAccountImage',
  createAccountImage = 'createAccountImage',
  lookupBik = 'lookupBik',
  lookupInn = 'lookupInn',
  fetchRegions = 'fetchRegions',
  fetchCompanySettings = 'fetchCompanySettings',
  fetchFileCompanySettings = 'fetchFileCompanySettings',
  updateFileCompanySettings = 'updateFileCompanySettings',
  updateCompanySettings = 'updateCompanySettings',
  uploadDataFile = 'uploadDataFile',
  uploadData = 'uploadData',
  fetchMagistrateCourts = 'fetchMagistrateCourts',
  fetchRegionalCourts = 'fetchRegionalCourts',
  fetchArbitrationCourts = 'fetchArbitrationCourts',
  fetchBailiffs = 'fetchBailiffs',
  fetchConstructorTemplateTypes = 'fetchConstructorTemplateTypes',
  createConstructorTemplateType = 'createConstructorTemplateType',
  removeConstructorTemplateType = 'removeConstructorTemplateType',
  fetchConstructorTemplates = 'fetchConstructorTemplates',
  removeConstructorTemplate = 'removeConstructorTemplate',
  updateConstructorTemplate = 'updateConstructorTemplate',
  createConstructorTemplate = 'createConstructorTemplate',

  fetchConstructorConstructorTemplates = 'fetchConstructorConstructorTemplates',
  fetchConstructorConstructorTemplate = 'fetchConstructorConstructorTemplate',
  removeConstructorConstructorTemplate = 'removeConstructorConstructorTemplate',
  createConstructorConstructorTemplate = 'createConstructorConstructorTemplate',
  updateConstructorConstructorTemplate = 'updateConstructorConstructorTemplate',

  fetchConstructorReportTemplateTypes = 'fetchConstructorReportTemplateTypes',
  createConstructorReportTemplateType = 'createConstructorReportTemplateType',
  removeConstructorReportTemplateType = 'removeConstructorReportTemplateType',
  fetchConstructorReportTemplates = 'fetchConstructorReportTemplates',
  fetchConstructorReportTemplate = 'fetchConstructorReportTemplate',
  createConstructorReportTemplate = 'createConstructorReportTemplate',
  updateConstructorReportTemplate = 'updateConstructorReportTemplate',
  removeConstructorReportTemplate = 'removeConstructorReportTemplate',
  fetchConstructorReportTemplateSettings = 'fetchConstructorReportTemplateSettings',
  createConstructorReportTemplateSettings = 'createConstructorReportTemplateSettings',
  updateConstructorReportTemplateSettings = 'updateConstructorReportTemplateSettings',
  removeConstructorReportTemplateSettings = 'removeConstructorReportTemplateSettings',

  fetchConstructorDataUploadTemplates = 'fetchConstructorDataUploadTemplates',
  fetchDocumentParsingTemplates = 'fetchDocumentParsingTemplates',
  fetchDocumentParsingTemplatesShort = 'fetchDocumentParsingTemplatesShort',
  fetchDocumentParsingTemplateColumnTypes = 'fetchDocumentParsingTemplateColumnTypes',
  fetchDocumentParsingTemplate = 'fetchDocumentParsingTemplate',
  createDocumentParsingTemplate = 'createDocumentParsingTemplate',
  updateDocumentParsingTemplate = 'updateDocumentParsingTemplate',
  removeDocumentParsingTemplate = 'removeDocumentParsingTemplate',
  fetchConstructorTemplatesShort = 'fetchConstructorTemplatesShort',
  updateConstructorParsingTemplate = 'updateConstructorParsingTemplate',
  assignConstructorTemplateToCompany = 'assignConstructorTemplateToCompany',
  unAssignConstructorTemplateFromCompany = 'unAssignConstructorTemplateFromCompany',
  fetchConstructorVariables = 'fetchConstructorVariables',
  fetchDebtors = 'fetchDebtors',
  fetchDebtorsTotals = 'fetchDebtorsTotals',
  fetchDebtor = 'fetchDebtor',
  fetchDebtorStatus = 'fetchDebtorStatus',
  createDebtorStatus = 'createDebtorStatus',
  updatePretrialStatus = 'updatePretrialStatus',
  fetchCompanyAttachments = 'fetchCompanyAttachments',
  updateCompanyAttachment = 'updateCompanyAttachment',
  fetchCompanyAttachmentsToPrint = 'fetchCompanyAttachmentsToPrint',
  fetchDefaultAttachments = 'fetchDefaultAttachments',
  deleteCompanyDocumentAttachment = 'deleteCompanyDocumentAttachment',
  fetchDefaultAttachmentStatus = 'fetchDefaultAttachmentStatus',
  generateMergedAttachments = 'generateMergedAttachments',
  createBulkAttachments = 'createBulkAttachments',
  constructorRender = 'constructorRender',
  constructorRenderWs = 'constructorRenderWs',
  constructorRenderDebtorsData = 'constructorRenderDebtorsData',
  constructorRenderDebtorsDataPostal = 'constructorRenderDebtorsDataPostal',
  constructorRenderHouseRegister = 'constructorRenderHouseRegister',
  extractFromEgrn = 'extractFromEgrn',
  sendVoice = 'sendVoice',
  sendSms = 'sendSms',
  sendEmail = 'sendEmail',
  fetchVoiceCallQueues = 'fetchVoiceCallQueues',
  updateVoiceCallQueue = 'updateVoiceCallQueue',
  deleteVoiceCallQueue = 'deleteVoiceCallQueue',
  fetchDebtorMainInfo = 'fetchDebtorMainInfo',
  updateDebtorMainInfo = 'updateDebtorMainInfo',
  updateTenant = 'updateTenant',
  createTenant = 'createTenant',
  removeTenant = 'removeTenant',
  updateInn = 'updateInn',
  updateVehicle = 'updateVehicle',
  fetchEstateObjectMovements = 'fetchEstateObjectMovements',
  fetchEstateObjectCharachteristics = 'fetchEstateObjectCharachteristics',
  fetchEstateRightInfo = 'fetchEstateRightInfo',
  fetchEstateEncumbranceInfo = 'fetchEstateEncumbranceInfo',
  fetchGeneralDocumentsFlow = 'fetchGeneralDocumentsFlow',
  fetchHousebook = 'fetchHousebook',
  fetchIdentityDocuments = 'fetchIdentityDocuments',
  fetchIdentityDocument = 'fetchIdentityDocument',
  createIdentityDocument = 'createIdentityDocument',
  updateIdentityDocument = 'updateIdentityDocument',
  deleteIdentityDocument = 'deleteIdentityDocument',
  fetchInnIdentityDocuments = 'fetchInnIdentityDocuments',
  fetchInnIdentityDocument = 'fetchInnIdentityDocument',
  createInnIdentityDocument = 'createInnIdentityDocument',
  updateInnIdentityDocument = 'updateInnIdentityDocument',
  deleteInnIdentityDocument = 'deleteInnIdentityDocument',
  fetchBCIdentityDocuments = 'fetchBCIdentityDocuments',
  fetchBCIdentityDocument = 'fetchBCIdentityDocument',
  createBCIdentityDocument = 'createBCIdentityDocument',
  updateBCIdentityDocument = 'updateBCIdentityDocument',
  deleteBCIdentityDocument = 'deleteBCIdentityDocument',
  fetchEgrnData = 'fetchEgrnData',
  fetchEgrnRights = 'fetchEgrnRights',
  fetchFeePayments = 'fetchFeePayments',
  fetchFeePayment = 'fetchFeePayment',
  createFeePayment = 'createFeePayment',
  updateFeePayment = 'updateFeePayment',
  deleteFeePayment = 'deleteFeePayment',
  fetchJudgments = 'fetchJudgments',
  fetchSms = 'fetchSms',
  fetchVoice = 'fetchVoice',
  fetchFnsHistory = 'fetchFnsHistory',
  fetchFsspDocumentsHistory = 'fetchFsspDocumentsHistory',
  fetchBankHistory = 'fetchBankHistory',
  fetchWritsOfExecution = 'fetchWritsOfExecution',
  fetchMyDocuments = 'fetchMyDocuments',
  fetchDocumentsImages = 'fetchDocumentsImages',
  fetchDocumentsImage = 'fetchDocumentsImage',
  deleteDocumentsImage = 'deleteDocumentsImage',
  fetchDebtorAgreements = 'fetchDebtorAgreements',
  fetchDebtorAgreement = 'fetchDebtorAgreement',
  createDebtorAgreement = 'createDebtorAgreement',
  updateDebtorAgreement = 'updateDebtorAgreement',
  deleteDebtorAgreement = 'deleteDebtorAgreement',
  createDebtorExtraAgreement = 'createDebtorExtraAgreement',
  updateDebtorExtraAgreement = 'updateDebtorExtraAgreement',
  deleteDebtorExtraAgreement = 'deleteDebtorExtraAgreement',
  fetchUniqueDebtorsDocuments = 'fetchUniqueDebtorsDocuments',
  fetchUniqueDebtorsDocument = 'fetchUniqueDebtorsDocument',
  removeUniqueDebtorsDocument = 'removeUniqueDebtorsDocument',
  uploadUniqueDebtorsDocument = 'uploadUniqueDebtorsDocument',
  updateUniqueDebtorsDocument = 'updateUniqueDebtorsDocument',
  createUniqueDebtorsDocument = 'createUniqueDebtorsDocument',
  fetchDebtorClaims = 'fetchDebtorClaims',
  fetchDebtorClaim = 'fetchDebtorClaim',
  createDebtorClaim = 'createDebtorClaim',
  updateDebtorClaim = 'updateDebtorClaim',
  removeDebtorClaim = 'removeDebtorClaim',
  uploadMyDocument = 'uploadMyDocument',
  uploadHousebookDocument = 'uploadHousebookDocument',
  removeMyDocument = 'removeMyDocument',
  removeGeneralDocument = 'removeGeneralDocument',
  removeEgrnDocument = 'removeEgrnDocument',
  removeEgrnTransferOfRights = 'removeEgrnTransferOfRights',
  removeHouseBookDocument = 'removeHouseBookDocument',
  fetchDebtorDefaultDocumentTypes = 'fetchDebtorDefaultDocumentTypes',
  createDebtorDefaultDocumentType = 'createDebtorDefaultDocumentType',
  updateDebtorDefaultDocumentType = 'updateDebtorDefaultDocumentType',
  deleteDebtorDefaultDocumentType = 'deleteDebtorDefaultDocumentType',
  fetchDebtorCustomDocumentTypes = 'fetchDebtorCustomDocumentTypes',
  createDebtorCustomDocumentType = 'createDebtorCustomDocumentType',
  updateDebtorCustomDocumentType = 'updateDebtorCustomDocumentType',
  deleteDebtorCustomDocumentType = 'deleteDebtorCustomDocumentType',
  fetchDebtorCustomDocuments = 'fetchDebtorCustomDocuments',
  uploadDebtorCustomDocument = 'uploadDebtorCustomDocument',
  updateDebtorCustomDocument = 'updateDebtorCustomDocument',
  deleteDebtorCustomDocument = 'deleteDebtorCustomDocument',
  fetchCourtCases = 'fetchCourtCases',
  updateCourtCaseByDebtor = 'updateCourtCaseByDebtor',
  removeCourtCase = 'removeCourtCase',
  createCourtCase = 'createCourtCase',
  updateCourtCase = 'updateCourtCase',
  createCourtCaseTracker = 'createCourtCaseTracker',
  updateCourtCaseTracker = 'updateCourtCaseTracker',
  fetchCourtCaseDetails = 'fetchCourtCaseDetails',
  updateCourtCaseDetails = 'updateCourtCaseDetails',
  fetchFsspDetails = 'fetchFsspDetails',
  updateFsspDetails = 'updateFsspDetails',
  fetchCourtRequisites = 'fetchCourtRequisites',
  updateCourtRequisites = 'updateCourtRequisites',
  refreshWritoe = 'refreshWritoe',
  createWritoe = 'createWritoe',
  fetchFssp = 'fetchFssp',
  fetchStandardizedDebtorAddresses = 'fetchStandardizedDebtorAddresses',
  updateStandardizedDebtorAddress = 'updateStandardizedDebtorAddress',
  setDefaultWritoe = 'setDefaultWritoe',
  fetchCompanyBalance = 'fetchCompanyBalance',
  fetchCompanyBalanceAccountingEntry = 'fetchCompanyBalanceAccountingEntry',
  fetchCompanyLicenses = 'fetchCompanyLicenses',
  fetchCompanyLicensesActiveCount = 'fetchCompanyLicensesActiveCount',
  companyLicenseBuy = 'companyLicenseBuy',
  fetchAvailableLicenses = 'fetchAvailableLicenses',
  moveDebtors = 'moveDebtors',
  confirmMoveDebtors = 'confirmMoveDebtors',
  compositeUpdate = 'compositeUpdate',
  fetchUserSettingsSecure = 'fetchUserSettingsSecure',
  createUserSettingsSecure = 'createUserSettingsSecure',
  deleteUserSettingsSecure = 'deleteUserSettingsSecure',
  createCompanySettingsSecure = 'createCompanySettingsSecure',
  fetchCompanySettingsSecure = 'fetchCompanySettingsSecure',
  updateCompanySettingsSecure = 'updateCompanySettingsSecure',
  deleteCompanySettingsSecure = 'deleteCompanySettingsSecure',
  sendingTestImapEmail = 'sendingTestImapEmail',
  checkAisGorodSignInTask = 'checkAisGorodSignInTask',
  checkAisGorodSignInResult = 'checkAisGorodSignInResult',
  fetchAisGorodServers = 'fetchAisGorodServers',
  fetchAisGorodProviderCodes = 'fetchAisGorodProviderCodes',
  fetchDebtorPochtaTracks = 'fetchDebtorPochtaTracks',
  fetchDebtorPochtaTrack = 'fetchDebtorPochtaTrack',
  createDebtorPochtaTrack = 'createDebtorPochtaTrack',
  updateDebtorPochtaTrack = 'updateDebtorPochtaTrack',
  removeDebtorPochtaTrack = 'removeDebtorPochtaTrack',
  createPochtaTask = 'createPochtaTask',
  fetchNeuroModelsTags = 'fetchNeuroModelsTags',
  uploadEnforcementsApplications = 'uploadEnforcementsApplications',
  fetchEnforcementsApplications = 'fetchEnforcementsApplications',
  fetchEnforcementsFsspArticles = 'fetchEnforcementsFsspArticles',
  fetchWritoeActions = 'fetchWritoeActions',

  fetchClientApiKey = 'fetchClientApiKey',
  generateClientApiKey = 'generateClientApiKey',

  updatesGetList = 'updatesGetList',

  scheduleDataPoolGetKeysList = 'scheduleDataPoolGetKeysList',
  scheduleDataPoolDeleteKey = 'scheduleDataPoolDeleteKey',
  scheduleDataPoolAddKeys = 'scheduleDataPoolAddKeys',
  scheduleBillingOnlineSolutionsTaskGetList = 'scheduleBillingOnlineSolutionsTaskGetList',
  scheduleBillingOnlineSolutionsCreateTask = 'scheduleBillingOnlineSolutionsCreateTask',
  scheduleBillingOnlineSolutionsDeleteTask = 'scheduleBillingOnlineSolutionsDeleteTask',
  scheduleAvailableCompanyPeriodicTaskGetList = 'scheduleAvailableCompanyPeriodicTaskGetList',
  scheduleCompanyPeriodicTaskGetList = 'scheduleCompanyPeriodicTaskGetList',
  scheduleCompanyPeriodicTaskCreate = 'scheduleCompanyPeriodicTaskCreate',
  scheduleCompanyPeriodicTaskUpdateSchedule = 'scheduleCompanyPeriodicTaskUpdateSchedule',
  scheduleCompanyPeriodicTaskDelete = 'scheduleCompanyPeriodicTaskDelete',

  scheduleBillingCompanyGetList = 'scheduleBillingCompanyGetList',
  scheduleBillingCompanyCreate = 'scheduleBillingCompanyCreate',
  scheduleBillingCompanyUpdate = 'scheduleBillingCompanyUpdate',
  scheduleBillingCompanyDelete = 'scheduleBillingCompanyDelete',

  scheduleBillingCompanyOperatorGetList = 'scheduleBillingCompanyOperatorGetList',

  debtorPhoneNumberCreate = 'debtorPhoneNumberCreate',
  debtorPhoneNumberUpdate = 'debtorPhoneNumberUpdate',
  debtorPhoneNumberDelete = 'debtorPhoneNumberDelete',

  fetchCourtFillingReport = 'fetchCourtFillingReport',
  fetchPrintReport = 'fetchPrintReport',
  fetchPrintReportId = 'fetchPrintReportId',
  readPrintReport = 'readPrintReport',
  deletePrintReport = 'deletePrintReport',

  companyPermissionsFetchList = 'companyPermissionsFetchList',
  companyPermissionsCreate = 'companyPermissionsCreate',
  companyPermissionsUpdate = 'companyPermissionsUpdate',
  companyPermissionsDelete = 'companyPermissionsDelete',

  fetchUserAgreement = 'fetchUserAgreement',

  notificationsFetchList = 'notificationsFetchList',
  notificationsMarkAsRead = 'notificationsMarkAsRead',

  fetchDebtorBankAccounts = 'fetchDebtorBankAccounts',
  fetchDebtorBankAccount = 'fetchDebtorBankAccount',
  updateDebtorBankAccount = 'updateDebtorBankAccount',
  createDebtorBankAccount = 'createDebtorBankAccount',
  deleteDebtorBankAccount = 'deleteDebtorBankAccount',

  fetchDebtorCourtDecision = 'fetchDebtorCourtDecision',
  addDebtorCourtDecision = 'addDebtorCourtDecision',
  updateDebtorCourtDecision = 'updateDebtorCourtDecision',
  removeDebtorCourtDecision = 'removeDebtorCourtDecision',
  getCaptcha = 'getCaptcha',

  debtorFinanceDataFetchList = 'debtorFinanceDataFetchList',
  debtorFinanceDataFetchItem = 'debtorFinanceDataFetchItem',
  debtorFinanceDataCreateItem = 'debtorFinanceDataCreateItem',
  debtorFinanceDataUpdateItem = 'debtorFinanceDataUpdateItem',
  debtorFinanceDataDeleteItem = 'debtorFinanceDataDeleteItem',

  companyDebtorStatusesFetchList = 'companyDebtorStatusesFetchList',
  companyDebtorStatusesForCustomStatusFetchList = 'companyDebtorStatusesForCustomStatusFetchList',

  rosreestrKeyGetList = 'rosreestrKeyGetList',
  rosreestrKeyGet = 'rosreestrKeyGet',
  rosreestrKeyAdd = 'rosreestrKeyAdd',
  rosreestrKeyDelete = 'rosreestrKeyDelete',

  pretrialClaimSettingsFetchList = 'pretrialClaimSettingsFetchList',
  pretrialClaimSettingsCreateItem = 'pretrialClaimSettingsCreateItem',
  pretrialClaimSettingsUpdateItem = 'pretrialClaimSettingsUpdateItem',
  pretrialClaimSettingsDeleteItem = 'pretrialClaimSettingsDeleteItem',

  // fsspUpdate = 'fsspUpdate',
  sendPretrialClaim = 'sendPretrialClaim',

  publicCompanySettingsFetchByCompanyDomain = 'publicCompanySettingsFetchByCompanyDomain',
  lockDebtorNotifications = 'lockDebtorNotifications',
  unlockDebtorNotifications = 'unlockDebtorNotifications',
  debtorDataGetFinances = 'debtorDataGetFinances',
  debtorDataGetHistory = 'debtorDataGetHistory',
  signDocumentFetchList = 'signDocumentFetchList',
  signDocumentUpdateItem = 'signDocumentUpdateItem',

  parseDocument = 'parseDocument',

  fetch1cCategories = 'fetch1cCategories',
  download1cConfiguration = 'download1cConfiguration',
  constructorValidateCompany = 'constructorValidateCompany',
  fetchInstruction = 'fetchInstruction',
  companyTemplateTypeFetchList = 'companyTemplateTypeFetchList',
  companyTemplateTypeCreate = 'companyTemplateTypeCreate',
  companyTemplateTypeDelete = 'companyTemplateTypeDelete',

  documentsExtractFromEgrnFetchList = 'documentsExtractFromEgrnFetchList',
  documentsExtractFromEgrnFetchItem = 'documentsExtractFromEgrnFetchItem',
  documentsExtractFromEgrnRender = 'documentsExtractFromEgrnRender',

  checkoutToUser = 'checkoutToUser',
  uploadDebtorRegions = 'uploadDebtorRegions',
  uploadDebtorEnrichment = 'uploadDebtorEnrichment',
  removeDebtorData = 'removeDebtorData',
  removeDebtorsPrevConvictedPeriodData = 'removeDebtorsPrevConvictedPeriodData',
  archiveDebtorData = 'archiveDebtorData',
  schedulerBillingCheck = 'schedulerBillingCheck',
  fetchDebtorCourtApplicationTracker = 'fetchDebtorCourtApplicationTracker',
  debtorStatusDocumentRecognition = 'debtorStatusDocumentRecognition',
  fetchWritoeFfspHistory = 'fetchWritoeFfspHistory',
  fetchDebtorSourcesOfIncome = 'fetchDebtorSourcesOfIncome',
  createDebtorSourceOfIncome = 'createDebtorSourceOfIncome',
  deleteDebtorSourceOfIncome = 'deleteDebtorSourceOfIncome',
  updateDebtorSourceOfIncome = 'updateDebtorSourceOfIncome',

  fetchUsersForDebtor = 'fetchUsersForDebtor',
  attachUserToDebtor = 'attachUserToDebtor',
  removeUserToDebtorBond = 'removeUserToDebtorBond',
  integrationEsiaTotpQR = 'integrationEsiaTotpQR',
  getCryptoProCertificate = 'getCryptoProCertificate',
  cryptoProCertificate = 'cryptoProCertificate',
  cryptoProCertificateSign = 'cryptoProCertificateSign',
  patchCryptoProCertificate = 'patchCryptoProCertificate',
  deleteCryptoProCertificate = 'deleteCryptoProCertificate',
}

export type ApiRequest<Data extends Record<any, any> = Record<any, any>>
  = ApiRequestGeneric<ApiCommand | ReportingApiCommand, Data>

export type ApiResponse<T extends any = any> = {
  status: boolean;
  response: T;
  statusCode?: number;
}

type ApiCommandsMap = {
  [key in ApiCommand]: ApiCommandConfig
}

export enum OrderDirection {
  asc = 'asc',
  desc = 'desc'
}

export type OrderMap<T extends Record<any, any>> = {
  [key in keyof T]: {
    order: number;
    direction: OrderDirection;
  }
}

export type Order<T extends Record<any, any>> = {
  key: keyof T;
  direction: OrderDirection;
}

export type ListingRequestSource<T extends Record<any, any>> = {
  ordering?: Array<Order<T>>;
  limit: number;
  page: number;
  offset?: number;
  filters?: ActiveFormModel<T>;
  signal?: AbortSignal;
}

export type ListingRequest<T extends Record<any, any> = Record<any, any>> = {
  ordering: string;
  limit: number;
  offset: number;
} & Partial<{
  [key in keyof T]: string | number;
}>

export type ListingResponse<T extends Record<any, any>> = {
  count: number;
  results: Array<T>;
  total?: Record<Partial<keyof T>, number>;
}

export type ListingParamsMap<T extends Record<any, any>> = (
  Pick<ListingRequest<T>, 'ordering' | 'limit' | 'offset'> & Partial<T>
) & Record<string, string>

export const apiCommands: ApiCommandsMap = {
  [ApiCommand.version]: {
    method: ApiMethod.get,
    url: '/version',
  },
  [ApiCommand.signIn]: {
    method: ApiMethod.post,
    url: '/auth/login/',
  },
  [ApiCommand.signUp]: {
    method: ApiMethod.post,
    url: '/api/account/register/',
  },
  [ApiCommand.doneSignUp]: {
    method: ApiMethod.put,
    url: '/api/account/register/',
  },
  [ApiCommand.signOut]: {
    method: ApiMethod.delete,
    url: '/auth/logout/',
  },
  [ApiCommand.fetchAuthData]: {
    method: ApiMethod.get,
    url: '/api/account/users/{id}/',
  },
  [ApiCommand.verify]: {
    method: ApiMethod.post,
    url: '/api/account/verificate/',
  },
  [ApiCommand.restore]: {
    method: ApiMethod.post,
    url: '/api/account/restore/',
  },
  [ApiCommand.doneRestore]: {
    method: ApiMethod.put,
    url: '/api/account/restore/',
  },
  [ApiCommand.changePassword]: {
    method: ApiMethod.patch,
    url: '/api/account/user/password/{id}/',
  },
  [ApiCommand.fetchCompanies]: {
    method: ApiMethod.get,
    url: '/api/account/company/',
  },
  [ApiCommand.fetchRegions]: {
    method: ApiMethod.get,
    url: '/api/common/region/',
  },
  [ApiCommand.fetchEmployees]: {
    method: ApiMethod.get,
    url: '/api/account/employee/',
  },
  [ApiCommand.fetchEmployee]: {
    method: ApiMethod.get,
    url: '/api/account/employee/{id}/',
  },
  [ApiCommand.fetchDefaultCompanyId]: {
    method: ApiMethod.get,
    url: '/api/account/user/active-company/{id}/',
  },
  [ApiCommand.updateCompany]: {
    method: ApiMethod.patch,
    url: '/api/account/company/{id}/',
  },
  [ApiCommand.updateEmployee]: {
    method: ApiMethod.patch,
    url: '/api/account/employee/{id}/',
  },
  [ApiCommand.resendEmployeePassword]: {
    method: ApiMethod.post,
    url: '/api/account/employee/{id}/resend_password/',
  },
  [ApiCommand.updateCompanySettings]: {
    method: ApiMethod.patch,
    url: '/api/account/company-settings/{id}/',
  },
  [ApiCommand.updateFileCompanySettings]: {
    method: ApiMethod.patch,
    url: '/api/account/file-company-settings/{id}/',
  },
  [ApiCommand.setDefaultCompanyId]: {
    method: ApiMethod.patch,
    url: '/api/account/user/active-company/{id}/',
  },
  [ApiCommand.addCompany]: {
    method: ApiMethod.post,
    url: '/api/account/company/',
  },
  [ApiCommand.fetchCompany]: {
    method: ApiMethod.get,
    url: '/api/account/company/{id}/',
  },
  [ApiCommand.fetchCompanySettings]: {
    method: ApiMethod.get,
    url: '/api/account/company-settings/{id}/',
  },
  [ApiCommand.fetchFileCompanySettings]: {
    method: ApiMethod.get,
    url: '/api/account/file-company-settings/{id}/',
  },
  [ApiCommand.removeCompany]: {
    method: ApiMethod.delete,
    url: '/api/account/company/{id}/',
  },
  [ApiCommand.removeEmployee]: {
    method: ApiMethod.delete,
    url: '/api/account/employee/{id}/',
  },
  [ApiCommand.createEmployee]: {
    method: ApiMethod.post,
    url: '/api/account/employee/',
  },
  [ApiCommand.fetchAccountDocuments]: {
    method: ApiMethod.get,
    url: '/api/account/document/',
  },
  [ApiCommand.fetchDocumentsImages]: {
    method: ApiMethod.get,
    url: '/documents/image/',
  },
  [ApiCommand.fetchDocumentsImage]: {
    method: ApiMethod.get,
    url: '/documents/image/{id}/',
  },
  [ApiCommand.deleteDocumentsImage]: {
    method: ApiMethod.delete,
    url: '/documents/image/{id}/',
  },
  [ApiCommand.removeAccountDocument]: {
    method: ApiMethod.delete,
    url: '/api/account/document/{id}/',
  },
  [ApiCommand.createAccountDocument]: {
    method: ApiMethod.post,
    url: '/api/account/document/',
  },
  [ApiCommand.updateAccountDocument]: {
    method: ApiMethod.patch,
    url: '/api/account/document/{id}/',
  },
  [ApiCommand.removeAccountImage]: {
    method: ApiMethod.delete,
    url: '/api/account/image/{id}/',
  },
  [ApiCommand.fetchAccountImages]: {
    method: ApiMethod.get,
    url: '/api/account/image/',
  },
  [ApiCommand.createAccountImage]: {
    method: ApiMethod.post,
    url: '/api/account/image/',
  },
  [ApiCommand.updateAccountImage]: {
    method: ApiMethod.patch,
    url: '/api/account/image/{id}/',
  },
  [ApiCommand.lookupBik]: {
    method: ApiMethod.post,
    url: '/lookup/bik/',
  },
  [ApiCommand.lookupInn]: {
    method: ApiMethod.post,
    url: '/externs/lookup/inn/',
  },
  [ApiCommand.uploadData]: {
    method: ApiMethod.post,
    url: '/api/datafile/upload/',
  },
  [ApiCommand.uploadDataFile]: {
    method: ApiMethod.post,
    url: '/api/datafile/file/',
  },
  [ApiCommand.fetchMagistrateCourts]: {
    method: ApiMethod.get,
    url: '/reference_books/magistrate_court_place/',
  },
  [ApiCommand.fetchRegionalCourts]: {
    method: ApiMethod.get,
    url: '/reference_books/regional_court_place/',
  },
  [ApiCommand.fetchArbitrationCourts]: {
    method: ApiMethod.get,
    url: '/reference_books/arbitration_court_place/',
  },
  [ApiCommand.fetchBailiffs]: {
    method: ApiMethod.get,
    url: '/reference_books/bailiff_place/',
  },
  [ApiCommand.fetchConstructorTemplateTypes]: {
    method: ApiMethod.get,
    url: '/constructor/template_type/',
  },
  [ApiCommand.fetchConstructorDataUploadTemplates]: {
    method: ApiMethod.get,
    url: '/constructor/data_upload_template/',
  },
  [ApiCommand.fetchConstructorTemplates]: {
    method: ApiMethod.get,
    url: '/constructor/company/template/',
  },
  [ApiCommand.removeConstructorTemplate]: {
    method: ApiMethod.delete,
    url: '/constructor/company/template/{id}/',
  },
  [ApiCommand.updateConstructorTemplate]: {
    method: ApiMethod.patch,
    url: '/constructor/company/template/{id}/',
  },
  [ApiCommand.createConstructorTemplate]: {
    method: ApiMethod.post,
    url: '/constructor/company/template/',
  },
  [ApiCommand.createConstructorTemplateType]: {
    method: ApiMethod.post,
    url: '/constructor/template_type/',
  },

  [ApiCommand.removeConstructorTemplateType]: {
    method: ApiMethod.delete,
    url: '/constructor/template_type/{id}/',
  },

  [ApiCommand.fetchConstructorReportTemplateTypes]: {
    method: ApiMethod.get,
    url: '/constructor/report/template_type/',
  },
  [ApiCommand.createConstructorReportTemplateType]: {
    method: ApiMethod.post,
    url: '/constructor/report/template_type/',
  },
  [ApiCommand.removeConstructorReportTemplateType]: {
    method: ApiMethod.delete,
    url: '/constructor/report/template_type/{id}/',
  },
  [ApiCommand.fetchConstructorReportTemplates]: {
    method: ApiMethod.get,
    url: '/constructor/report/template/',
  },
  [ApiCommand.fetchConstructorReportTemplate]: {
    method: ApiMethod.get,
    url: '/constructor/report/template/{id}/',
  },
  [ApiCommand.createConstructorReportTemplate]: {
    method: ApiMethod.post,
    url: '/constructor/report/template/',
  },
  [ApiCommand.updateConstructorReportTemplate]: {
    method: ApiMethod.patch,
    url: '/constructor/report/template/{id}/',
  },
  [ApiCommand.removeConstructorReportTemplate]: {
    method: ApiMethod.delete,
    url: '/constructor/report/template/{id}/',
  },
  [ApiCommand.fetchConstructorReportTemplateSettings]: {
    method: ApiMethod.get,
    url: '/constructor/report/template_settings',
  },
  [ApiCommand.createConstructorReportTemplateSettings]: {
    method: ApiMethod.post,
    url: '/constructor/report/template_settings/',
  },
  [ApiCommand.updateConstructorReportTemplateSettings]: {
    method: ApiMethod.patch,
    url: '/constructor/report/template_settings/{id}/',
  },
  [ApiCommand.removeConstructorReportTemplateSettings]: {
    method: ApiMethod.delete,
    url: '/constructor/report/template_settings/{id}/',
  },
  // CONSTRUCTOR CONSTRUCTOR
  [ApiCommand.fetchConstructorConstructorTemplates]: {
    method: ApiMethod.get,
    url: '/constructor/template/',
  },
  [ApiCommand.fetchConstructorConstructorTemplate]: {
    method: ApiMethod.get,
    url: '/constructor/template/{id}/',
  },
  [ApiCommand.removeConstructorConstructorTemplate]: {
    method: ApiMethod.delete,
    url: '/constructor/template/{id}/',
  },
  [ApiCommand.createConstructorConstructorTemplate]: {
    method: ApiMethod.post,
    url: '/constructor/template/',
  },
  [ApiCommand.updateConstructorConstructorTemplate]: {
    method: ApiMethod.patch,
    url: '/constructor/template/{id}/',
  },
  [ApiCommand.fetchDocumentParsingTemplates]: {
    method: ApiMethod.get,
    url: '/api/document-parsing/templates/',
  },
  [ApiCommand.fetchDocumentParsingTemplatesShort]: {
    method: ApiMethod.get,
    url: '/api/document-parsing/templates/short/',
  },
  [ApiCommand.fetchDocumentParsingTemplateColumnTypes]: {
    method: ApiMethod.get,
    url: '/api/document-parsing/column_groups',
  },
  [ApiCommand.fetchDocumentParsingTemplate]: {
    method: ApiMethod.get,
    url: '/api/document-parsing/templates/{id}/',
  },
  [ApiCommand.updateDocumentParsingTemplate]: {
    method: ApiMethod.patch,
    url: '/api/document-parsing/templates/{id}/',
  },
  [ApiCommand.removeDocumentParsingTemplate]: {
    method: ApiMethod.delete,
    url: '/api/document-parsing/templates/{id}/',
  },
  [ApiCommand.createDocumentParsingTemplate]: {
    method: ApiMethod.post,
    url: '/api/document-parsing/templates/',
  },
  [ApiCommand.fetchConstructorTemplatesShort]: {
    method: ApiMethod.get,
    url: '/api/document-parsing/templates/short/',
  },
  [ApiCommand.updateConstructorParsingTemplate]: {
    method: ApiMethod.patch,
    url: '/api/document-parsing/templates/{id}/',
  },
  [ApiCommand.assignConstructorTemplateToCompany]: {
    method: ApiMethod.get,
    url: '/api/document-parsing/templates/{id}/assign/',
  },
  [ApiCommand.unAssignConstructorTemplateFromCompany]: {
    method: ApiMethod.get,
    url: '/api/document-parsing/templates/{id}/unassign/',
  },
  [ApiCommand.fetchConstructorVariables]: {
    method: ApiMethod.get,
    url: '/constructor/vars',
  },
  [ApiCommand.fetchDebtors]: {
    method: ApiMethod.get,
    url: '/api/debtors-data/',
  },
  [ApiCommand.fetchDebtorsTotals]: {
    method: ApiMethod.get,
    url: '/api/debtors-data/total/',
  },
  [ApiCommand.fetchDebtor]: {
    method: ApiMethod.get,
    url: '/api/debtors-data/{id}/',
  },
  [ApiCommand.fetchDebtorStatuses]: {
    method: ApiMethod.get,
    url: '/debtor_status/consts',
  },
  [ApiCommand.fetchDebtorStatusesForFilters]: {
    method: ApiMethod.get,
    url: '/debtor_status/filter_consts',
  },
  [ApiCommand.fetchDebtorBorrowers]: {
    method: ApiMethod.get,
    url: '/debtor/borrower/',
  },
  [ApiCommand.fetchDebtorBorrower]: {
    method: ApiMethod.get,
    url: '/debtor/borrower/{id}/',
  },
  [ApiCommand.createDebtorBorrower]: {
    method: ApiMethod.post,
    url: '/debtor/borrower/',
  },
  [ApiCommand.updateDebtorBorrower]: {
    method: ApiMethod.patch,
    url: '/debtor/borrower/{id}/',
  },
  [ApiCommand.removeDebtorBorrower]: {
    method: ApiMethod.delete,
    url: '/debtor/borrower/{id}/',
  },
  [ApiCommand.fetchDebtorStatus]: {
    method: ApiMethod.get,
    url: '/debtor_status/',
  },
  [ApiCommand.createDebtorStatus]: {
    method: ApiMethod.post,
    url: '/debtor_status/',
  },
  [ApiCommand.updatePretrialStatus]: {
    method: ApiMethod.post,
    url: '/pretrial/debtor/{id}/debtor_status/',
  },
  [ApiCommand.attachmentsList]: {
    method: ApiMethod.get,
    url: '/api/account/attachment/',
  },
  [ApiCommand.addAttachmentsList]: {
    method: ApiMethod.post,
    url: '/api/account/attachment/',
  },
  [ApiCommand.attachmentsById]: {
    method: ApiMethod.get,
    url: '/api/account/attachment/{id}/',
  },
  [ApiCommand.attachmentsUpdateById]: {
    method: ApiMethod.patch,
    url: '/api/account/attachment/{id}/',
  },
  [ApiCommand.attachmentsDeleteById]: {
    method: ApiMethod.delete,
    url: '/api/account/attachment/{id}/',
  },
  [ApiCommand.getCompanyServices]: {
    method: ApiMethod.get,
    url: '/api/account/company-services/',
  },
  [ApiCommand.getCompanyPositions]: {
    method: ApiMethod.get,
    url: '/api/account/company/{company_id}/positions/',
  },
  [ApiCommand.addCompanyPositions]: {
    method: ApiMethod.post,
    url: '/api/account/company/{company_id}/positions/',
  },
  [ApiCommand.getCompanyPositionsById]: {
    method: ApiMethod.get,
    url: '/api/account/company/{company_id}/positions/{id}/',
  },
  [ApiCommand.updateCompanyPositionById]: {
    method: ApiMethod.patch,
    url: '/api/account/company/{company_id}/positions/{id}/',
  },
  [ApiCommand.deleteCompanyPosition]: {
    method: ApiMethod.delete,
    url: '/api/account/company/{company_id}/positions/{id}/',
  },
  [ApiCommand.getCompanyServicesByCompanyId]: {
    method: ApiMethod.get,
    url: '/api/account/company/{company_id}/services/',
  },
  [ApiCommand.addCompanyServicesByCompanyId]: {
    method: ApiMethod.post,
    url: '/api/account/company/{company_id}/services/',
  },
  [ApiCommand.getCompanyServicesByServicesId]: {
    method: ApiMethod.get,
    url: '/api/account/company/{company_id}/services/{id}/',
  },
  [ApiCommand.updateCompanyServicesByServicesId]: {
    method: ApiMethod.patch,
    url: '/api/account/company/{company_id}/services/{id}/',
  },
  [ApiCommand.deleteCompanyServicesByServicesId]: {
    method: ApiMethod.delete,
    url: '/api/account/company/{company_id}/services/{id}/',
  },
  [ApiCommand.getAccountLog]: {
    method: ApiMethod.get,
    url: '/api/account/log/',
  },
  [ApiCommand.getAccountLogById]: {
    method: ApiMethod.get,
    url: '/api/account/log/{id}/',
  },
  [ApiCommand.deleteAccountLog]: {
    method: ApiMethod.delete,
    url: '/api/account/log/{id}/',
  },
  [ApiCommand.getAccountPosition]: {
    method: ApiMethod.get,
    url: '/api/account/position/',
  },
  [ApiCommand.getUsersList]: {
    method: ApiMethod.get,
    url: '/api/account/user/',
  },
  [ApiCommand.addUser]: {
    method: ApiMethod.post,
    url: '/api/account/user/',
  },
  [ApiCommand.getUserCompanyList]: {
    method: ApiMethod.get,
    url: '/api/account/user/companies/{id}/',
  },
  [ApiCommand.assignCompanyToUser]: {
    method: ApiMethod.patch,
    url: '/api/account/user/companies/{id}/',
    dataMap: {
      linked_companies: 'linked_companies',
    },
  },
  [ApiCommand.getUserInterfaceSettings]: {
    method: ApiMethod.get,
    url: '/api/account/user/prefs/{id}/',
  },
  [ApiCommand.updateUserInterfaceSettings]: {
    method: ApiMethod.patch,
    url: '/api/account/user/prefs/{id}/',
  },
  [ApiCommand.getUserData]: {
    method: ApiMethod.get,
    url: '/api/account/user/{id}/',
  },
  [ApiCommand.updateUserData]: {
    method: ApiMethod.patch,
    url: '/api/account/user/{id}/',
  },
  [ApiCommand.deleteUser]: {
    method: ApiMethod.delete,
    url: '/api/account/user/{id}/',
  },
  [ApiCommand.getAllUsers]: {
    method: ApiMethod.get,
    url: '/api/account/users/',
  },
  [ApiCommand.getDataFileList]: {
    method: ApiMethod.get,
    url: '/api/datafile/list/',
  },
  [ApiCommand.getDataFileStatus]: {
    method: ApiMethod.get,
    url: '/api/datafile/status/',
  },
  [ApiCommand.getDataFilePackage]: {
    method: ApiMethod.get,
    url: '/api/datafile/package/{id}/',
  },
  // обновляет статусы не только в судебке
  [ApiCommand.addJudicialStatus]: {
    method: ApiMethod.post,
    url: '/api/debtors-data/judicial/status/',
  },
  [ApiCommand.moveDebtor]: {
    method: ApiMethod.post,
    url: '/api/debtors-data/move/',
  },
  [ApiCommand.applyMoveDebtor]: {
    method: ApiMethod.post,
    url: '/api/debtors-data/move/confirm/',
  },
  [ApiCommand.fetchCompanyAttachments]: {
    method: ApiMethod.get,
    url: '/document_attachments/company/',
  },
  [ApiCommand.updateCompanyAttachment]: {
    method: ApiMethod.patch,
    url: '/document_attachments/company/{id}/',
  },
  [ApiCommand.fetchCompanyAttachmentsToPrint]: {
    method: ApiMethod.get,
    url: '/api/account/document/',
  },
  [ApiCommand.fetchDefaultAttachments]: {
    method: ApiMethod.get,
    url: '/document_attachments/defaults/',
  },
  [ApiCommand.fetchDefaultAttachmentStatus]: {
    method: ApiMethod.get,
    url: '/document_attachments/status/{id}/',
  },
  [ApiCommand.deleteCompanyDocumentAttachment]: {
    method: ApiMethod.delete,
    url: '/document_attachments/company/{id}/',
  },
  [ApiCommand.generateMergedAttachments]: {
    method: ApiMethod.post,
    url: '/document_attachments/generate_merged/',
  },
  [ApiCommand.createBulkAttachments]: {
    method: ApiMethod.post,
    url: '/document_attachments/company_bulk_create/',
  },
  [ApiCommand.constructorRender]: {
    method: ApiMethod.post,
    url: '/constructor/render/',
  },
  [ApiCommand.constructorRenderWs]: {
    method: ApiMethod.post,
    url: '/constructor/report/render/',
  },
  [ApiCommand.constructorRenderDebtorsData]: {
    method: ApiMethod.post,
    url: '/constructor/debtors-data/',
  },
  [ApiCommand.constructorRenderDebtorsDataPostal]: {
    method: ApiMethod.post,
    url: '/constructor/debtors-data-postal-inventory/',
  },
  [ApiCommand.constructorRenderHouseRegister]: {
    method: ApiMethod.post,
    url: '/constructor/house_register/render/',
  },
  [ApiCommand.extractFromEgrn]: {
    method: ApiMethod.post,
    url: '/rosreestr/status/',
  },
  [ApiCommand.sendVoice]: {
    method: ApiMethod.post,
    url: '/pretrial/voice/',
  },
  [ApiCommand.sendSms]: {
    method: ApiMethod.post,
    url: '/pretrial/sms/',
  },
  [ApiCommand.sendEmail]: {
    method: ApiMethod.post,
    url: '/pretrial/email/',
  },
  [ApiCommand.updateVoiceCallQueue]: {
    method: ApiMethod.patch,
    url: '/pretrial/voice_call_queue/{id}',
  },
  [ApiCommand.fetchVoiceCallQueues]: {
    method: ApiMethod.get,
    url: '/pretrial/voice_call_queue/',
  },
  [ApiCommand.deleteVoiceCallQueue]: {
    method: ApiMethod.delete,
    url: '/pretrial/voice_call_queue/{id}',
  },
  [ApiCommand.fetchDebtorMainInfo]: {
    method: ApiMethod.get,
    url: '/debtor/main_profile/{id}/',
  },
  [ApiCommand.updateDebtorMainInfo]: {
    method: ApiMethod.patch,
    url: '/debtor/main_profile/{id}/',
  },
  [ApiCommand.updateTenant]: {
    method: ApiMethod.patch,
    url: '/debtor/tenant/{id}/',
  },
  [ApiCommand.createTenant]: {
    method: ApiMethod.post,
    url: '/debtor/tenant/',
  },
  [ApiCommand.removeTenant]: {
    method: ApiMethod.delete,
    url: '/debtor/tenant/{id}/',
  },
  [ApiCommand.updateInn]: {
    method: ApiMethod.post,
    url: '/debtor/update-inn/',
  },
  [ApiCommand.compositeUpdate]: {
    method: ApiMethod.post,
    url: '/api/datafile/package/composite/',
  },
  [ApiCommand.updateVehicle]: {
    method: ApiMethod.post,
    url: '/debtor/update-vehicle/',
  },
  [ApiCommand.fetchEstateObjectMovements]: {
    method: ApiMethod.get,
    url: '/rosreestr/estate_object_ownership_movement/',
  },
  [ApiCommand.fetchEstateObjectCharachteristics]: {
    method: ApiMethod.get,
    url: '/rosreestr/estate_objects_characteristics/',
  },
  [ApiCommand.fetchEstateRightInfo]: {
    method: ApiMethod.get,
    url: '/rosreestr/{company_id}/estate_right_info/',
  },
  [ApiCommand.fetchEstateEncumbranceInfo]: {
    method: ApiMethod.get,
    url: '/rosreestr/{id}/estate_encumbrance_info/',
  },
  [ApiCommand.fetchGeneralDocumentsFlow]: {
    method: ApiMethod.get,
    url: '/documents/general_document_flow/',
  },
  [ApiCommand.fetchHousebook]: {
    method: ApiMethod.get,
    url: '/documents/extract_house_book/',
  },
  [ApiCommand.fetchIdentityDocuments]: {
    method: ApiMethod.get,
    url: '/documents/identity/',
  },
  [ApiCommand.fetchIdentityDocument]: {
    method: ApiMethod.get,
    url: '/documents/identity/{id}',
  },
  [ApiCommand.createIdentityDocument]: {
    method: ApiMethod.post,
    url: '/documents/identity/',
  },
  [ApiCommand.updateIdentityDocument]: {
    method: ApiMethod.patch,
    url: '/documents/identity/{id}',
  },
  [ApiCommand.deleteIdentityDocument]: {
    method: ApiMethod.delete,
    url: '/documents/identity/{id}',
  },
  [ApiCommand.fetchInnIdentityDocuments]: {
    method: ApiMethod.get,
    url: '/documents/inn_identity/',
  },
  [ApiCommand.fetchInnIdentityDocument]: {
    method: ApiMethod.get,
    url: '/documents/inn_identity/{id}',
  },
  [ApiCommand.createInnIdentityDocument]: {
    method: ApiMethod.post,
    url: '/documents/inn_identity/',
  },
  [ApiCommand.updateInnIdentityDocument]: {
    method: ApiMethod.patch,
    url: '/documents/inn_identity/{id}',
  },
  [ApiCommand.deleteInnIdentityDocument]: {
    method: ApiMethod.delete,
    url: '/documents/inn_identity/{id}',
  },
  [ApiCommand.fetchBCIdentityDocuments]: {
    method: ApiMethod.get,
    url: '/documents/birth_certificate_identity/',
  },
  [ApiCommand.fetchBCIdentityDocument]: {
    method: ApiMethod.get,
    url: '/documents/birth_certificate_identity/{id}',
  },
  [ApiCommand.createBCIdentityDocument]: {
    method: ApiMethod.post,
    url: '/documents/birth_certificate_identity/',
  },
  [ApiCommand.updateBCIdentityDocument]: {
    method: ApiMethod.patch,
    url: '/documents/birth_certificate_identity/{id}',
  },
  [ApiCommand.deleteBCIdentityDocument]: {
    method: ApiMethod.delete,
    url: '/documents/birth_certificate_identity/{id}',
  },
  [ApiCommand.fetchEgrnData]: {
    method: ApiMethod.get,
    url: '/documents/extract_from_egrn/',
  },
  [ApiCommand.fetchEgrnRights]: {
    method: ApiMethod.get,
    url: '/documents/extract_from_egrn_transfer_of_rights/',
  },
  [ApiCommand.fetchFeePayments]: {
    method: ApiMethod.get,
    url: '/judicial/debtor/{debtor_id}/payments/',
  },
  [ApiCommand.fetchFeePayment]: {
    method: ApiMethod.get,
    url: '/judicial/debtor/{debtor_id}/payments/{id}/',
  },
  [ApiCommand.createFeePayment]: {
    method: ApiMethod.post,
    url: '/judicial/debtor/{debtor_id}/payments/',
  },
  [ApiCommand.updateFeePayment]: {
    method: ApiMethod.patch,
    url: '/judicial/debtor/{debtor_id}/payments/{id}/',
  },
  [ApiCommand.deleteFeePayment]: {
    method: ApiMethod.delete,
    url: '/judicial/debtor/{debtor_id}/payments/{id}/',
  },
  [ApiCommand.fetchJudgments]: {
    method: ApiMethod.get,
    url: '/judicial/debtor/{debtor_id}/decisions/',
  },
  [ApiCommand.fetchSms]: {
    method: ApiMethod.get,
    url: '/pretrial/debtor/{debtor_id}/sms/',
  },
  [ApiCommand.fetchVoice]: {
    method: ApiMethod.get,
    url: '/pretrial/debtor/{debtor_id}/voice/',
  },
  [ApiCommand.fetchFnsHistory]: {
    method: ApiMethod.get,
    url: '/executive/executive_fns_history/',
  },
  [ApiCommand.fetchFsspDocumentsHistory]: {
    method: ApiMethod.get,
    url: '/enforcements/executive_fssp_history/',
  },
  [ApiCommand.fetchBankHistory]: {
    method: ApiMethod.get,
    url: '/executive/executive_bank_history/',
  },
  [ApiCommand.fetchWritsOfExecution]: {
    method: ApiMethod.get,
    url: '/executive/company/{company_id}/writoe/',
  },
  [ApiCommand.fetchMyDocuments]: {
    method: ApiMethod.get,
    url: '/documents/debtor/',
  },
  [ApiCommand.fetchUniqueDebtorsDocuments]: {
    method: ApiMethod.get,
    url: '/documents/unique_debtor/',
  },
  [ApiCommand.fetchUniqueDebtorsDocument]: {
    method: ApiMethod.get,
    url: '/documents/unique_debtor/{id}/',
  },
  [ApiCommand.createUniqueDebtorsDocument]: {
    method: ApiMethod.post,
    url: '/documents/unique_debtor/',
  },
  [ApiCommand.uploadUniqueDebtorsDocument]: {
    method: ApiMethod.post,
    url: '/documents/unique_debtor/',
  },
  [ApiCommand.updateUniqueDebtorsDocument]: {
    method: ApiMethod.patch,
    url: '/documents/unique_debtor/{id}',
  },
  [ApiCommand.fetchDebtorClaims]: {
    method: ApiMethod.get,
    url: '/documents/claim/',
  },
  [ApiCommand.fetchDebtorClaim]: {
    method: ApiMethod.get,
    url: '/documents/claim/{id}/',
  },
  [ApiCommand.createDebtorClaim]: {
    method: ApiMethod.post,
    url: '/documents/claim/',
  },
  [ApiCommand.updateDebtorClaim]: {
    method: ApiMethod.patch,
    url: '/documents/claim/{id}/',
  },
  [ApiCommand.removeDebtorClaim]: {
    method: ApiMethod.delete,
    url: '/documents/claim/{id}/',
  },
  [ApiCommand.removeUniqueDebtorsDocument]: {
    method: ApiMethod.delete,
    url: '/documents/unique_debtor/{id}/',
  },
  [ApiCommand.uploadMyDocument]: {
    method: ApiMethod.post,
    url: '/documents/debtor/',
  },
  [ApiCommand.uploadHousebookDocument]: {
    method: ApiMethod.post,
    url: '/documents/extract_house_book/',
  },
  [ApiCommand.removeMyDocument]: {
    method: ApiMethod.delete,
    url: '/documents/debtor/{id}/',
  },
  [ApiCommand.removeGeneralDocument]: {
    method: ApiMethod.delete,
    url: '/documents/general_document_flow/{id}/',
  },
  [ApiCommand.removeEgrnDocument]: {
    method: ApiMethod.delete,
    url: '/documents/extract_from_egrn/{id}/',
  },
  [ApiCommand.removeEgrnTransferOfRights]: {
    method: ApiMethod.delete,
    url: '/documents/extract_from_egrn_transfer_of_rights/{id}/',
  },
  [ApiCommand.removeHouseBookDocument]: {
    method: ApiMethod.delete,
    url: '/documents/extract_house_book/{id}/',
  },
  [ApiCommand.fetchDebtorDefaultDocumentTypes]: {
    method: ApiMethod.get,
    url: '/documents/debtor_default_type/',
  },
  [ApiCommand.createDebtorDefaultDocumentType]: {
    method: ApiMethod.post,
    url: '/documents/debtor_default_type/',
  },
  [ApiCommand.updateDebtorDefaultDocumentType]: {
    method: ApiMethod.patch,
    url: '/documents/debtor_default_type/{id}/',
  },
  [ApiCommand.deleteDebtorDefaultDocumentType]: {
    method: ApiMethod.delete,
    url: '/documents/debtor_default_type/{id}/',
  },
  [ApiCommand.fetchDebtorCustomDocumentTypes]: {
    method: ApiMethod.get,
    url: '/documents/debtor_custom_type/',
  },
  [ApiCommand.createDebtorCustomDocumentType]: {
    method: ApiMethod.post,
    url: '/documents/debtor_custom_type/',
  },
  [ApiCommand.updateDebtorCustomDocumentType]: {
    method: ApiMethod.patch,
    url: '/documents/debtor_custom_type/{id}/',
  },
  [ApiCommand.deleteDebtorCustomDocumentType]: {
    method: ApiMethod.delete,
    url: '/documents/debtor_custom_type/{id}/',
  },
  [ApiCommand.fetchDebtorCustomDocuments]: {
    method: ApiMethod.get,
    url: '/documents/debtor_custom/',
  },
  [ApiCommand.uploadDebtorCustomDocument]: {
    method: ApiMethod.post,
    url: '/documents/debtor_custom/',
  },
  [ApiCommand.updateDebtorCustomDocument]: {
    method: ApiMethod.patch,
    url: '/documents/debtor_custom/{id}/',
  },
  [ApiCommand.deleteDebtorCustomDocument]: {
    method: ApiMethod.delete,
    url: '/documents/debtor_custom/{id}/',
  },
  [ApiCommand.fetchCourtCases]: {
    method: ApiMethod.get,
    url: '/reference_books/court_cases_history/',
  },
  [ApiCommand.updateCourtCaseByDebtor]: {
    method: ApiMethod.post,
    url: '/reference_books/court_cases_history/find_by_name/',
  },
  [ApiCommand.removeCourtCase]: {
    method: ApiMethod.delete,
    url: '/reference_books/court_cases_history/{id}/',
  },
  [ApiCommand.updateCourtCase]: {
    method: ApiMethod.patch,
    url: '/reference_books/court_cases_history/{id}/',
  },
  [ApiCommand.createCourtCase]: {
    method: ApiMethod.post,
    url: '/reference_books/court_cases_history/',
  },
  [ApiCommand.updateCourtCaseTracker]: {
    method: ApiMethod.patch,
    url: '/reference_books/court_cases_tracker/{id}/',
  },
  [ApiCommand.createCourtCaseTracker]: {
    method: ApiMethod.post,
    url: '/reference_books/court_cases_tracker/',
  },
  [ApiCommand.fetchCourtCaseDetails]: {
    method: ApiMethod.get,
    url: '/reference_books/court_cases_history/{id}/info',
  },
  [ApiCommand.updateCourtCaseDetails]: {
    method: ApiMethod.patch,
    url: '/reference_books/court_cases_history/{id}/info',
  },
  [ApiCommand.fetchFsspDetails]: {
    method: ApiMethod.get,
    url: 'executive/company/{company_id}/writoe/{id}/info/',
  },
  [ApiCommand.updateFsspDetails]: {
    method: ApiMethod.patch,
    url: 'executive/company/{company_id}/writoe/{id}/info/',
  },
  [ApiCommand.fetchCourtRequisites]: {
    method: ApiMethod.get,
    url: '/reference_books/{type}_court_place/{id}/',
  },
  [ApiCommand.updateCourtRequisites]: {
    method: ApiMethod.patch,
    url: '/reference_books/{type}_court_place/{id}/',
  },
  [ApiCommand.refreshWritoe]: {
    method: ApiMethod.post,
    url: '/executive/company/{company_id}/writoe/{id}/refresh/',
  },
  [ApiCommand.createWritoe]: {
    method: ApiMethod.post,
    url: '/executive/company/{company_id}/writoe/create/{debtor_id}/',
  },
  [ApiCommand.fetchFssp]: {
    method: ApiMethod.get,
    url: '/executive/company/{company_id}/fssp/',
  },
  [ApiCommand.fetchStandardizedDebtorAddresses]: {
    method: ApiMethod.get,
    url: '/debtor/standardizeddebtoraddress/{id}/',
  },
  [ApiCommand.updateStandardizedDebtorAddress]: {
    method: ApiMethod.patch,
    url: '/debtor/standardizeddebtoraddress/{id}/',
  },
  [ApiCommand.setDefaultWritoe]: {
    method: ApiMethod.patch,
    url: '/executive/company/{company_id}/writoe/{id}/',
  },
  [ApiCommand.fetchCompanyBalance]: {
    method: ApiMethod.get,
    url: '/api/finance/balance/{id}/',
  },
  [ApiCommand.fetchCompanyBalanceAccountingEntry]: {
    method: ApiMethod.get,
    url: '/api/finance/balance/{id}/accounting_entry/',
  },
  [ApiCommand.fetchAvailableLicenses]: {
    method: ApiMethod.get,
    url: '/api/finance/license/',
  },
  [ApiCommand.fetchCompanyLicenses]: {
    method: ApiMethod.get,
    url: '/api/finance/active_license/{id}/',
  },
  [ApiCommand.fetchCompanyLicensesActiveCount]: {
    method: ApiMethod.get,
    url: '/api/finance/active_license/{id}/active_count',
  },
  [ApiCommand.companyLicenseBuy]: {
    method: ApiMethod.post,
    url: '/api/finance/active_license/{id}/buy/',
  },

  [ApiCommand.moveDebtors]: {
    method: ApiMethod.post,
    url: '/api/debtors-data-move/',
  },
  [ApiCommand.confirmMoveDebtors]: {
    method: ApiMethod.patch,
    url: '/api/debtors-data-move/{move_id}/confirm/',
  },
  [ApiCommand.fetchUserSettingsSecure]: {
    method: ApiMethod.get,
    url: '/user_settings/secure/',
  },
  [ApiCommand.createUserSettingsSecure]: {
    method: ApiMethod.post,
    url: '/user_settings/secure/',
  },
  [ApiCommand.deleteUserSettingsSecure]: {
    method: ApiMethod.delete,
    url: '/user_settings/secure/{id}/',
  },
  [ApiCommand.fetchCompanySettingsSecure]: {
    method: ApiMethod.get,
    url: '/api/account/company/{company_id}/secure/',
  },
  [ApiCommand.createCompanySettingsSecure]: {
    method: ApiMethod.post,
    url: '/api/account/company/{company_id}/secure/',
  },
  [ApiCommand.updateCompanySettingsSecure]: {
    method: ApiMethod.patch,
    url: '/api/account/company/{company_id}/secure/{id}/',
  },
  [ApiCommand.deleteCompanySettingsSecure]: {
    method: ApiMethod.delete,
    url: '/api/account/company/{company_id}/secure/{id}/',
  },
  [ApiCommand.sendingTestImapEmail]: {
    method: ApiMethod.post,
    url: '/integrations/imap/send_email/',
  },
  [ApiCommand.checkAisGorodSignInTask]: {
    method: ApiMethod.post,
    url: 'externs/ais_gorod/login_check_task/',
  },
  [ApiCommand.checkAisGorodSignInResult]: {
    method: ApiMethod.post,
    url: '/externs/ais_gorod/login_check_result/',
  },
  [ApiCommand.fetchAisGorodServers]: {
    method: ApiMethod.post,
    url: '/externs/ais_gorod/servers/',
  },
  [ApiCommand.fetchAisGorodProviderCodes]: {
    method: ApiMethod.post,
    url: '/externs/ais_gorod/provider_codes/',
  },
  [ApiCommand.fetchDebtorPochtaTracks]: {
    method: ApiMethod.get,
    url: '/externs/pochta/',
  },
  [ApiCommand.fetchDebtorPochtaTrack]: {
    method: ApiMethod.get,
    url: '/externs/pochta/{id}',
  },
  [ApiCommand.createDebtorPochtaTrack]: {
    method: ApiMethod.post,
    url: '/externs/pochta/',
  },
  [ApiCommand.updateDebtorPochtaTrack]: {
    method: ApiMethod.patch,
    url: '/externs/pochta/{id}/',
  },
  [ApiCommand.removeDebtorPochtaTrack]: {
    method: ApiMethod.delete,
    url: '/externs/pochta/{id}/',
  },
  [ApiCommand.createPochtaTask]: {
    method: ApiMethod.post,
    url: '/externs/pochta_task/',
  },
  [ApiCommand.fetchNeuroModelsTags]: {
    method: ApiMethod.post,
    url: '/externs/neuro/model_tags/',
  },
  [ApiCommand.fetchClientApiKey]: {
    method: ApiMethod.get,
    url: '/api/account/company-settings/{company_id}/api_key/',
  },
  [ApiCommand.generateClientApiKey]: {
    method: ApiMethod.patch,
    url: '/api/account/company-settings/{company_id}/api_key/',
  },
  [ApiCommand.updatesGetList]: {
    method: ApiMethod.get,
    url: '/api/common/updates/',
  },
  [ApiCommand.scheduleAvailableCompanyPeriodicTaskGetList]: {
    method: ApiMethod.get,
    url: '/scheduler/available_company_periodic_tasks/',
  },
  [ApiCommand.scheduleCompanyPeriodicTaskGetList]: {
    method: ApiMethod.get,
    url: '/scheduler/company_periodic_tasks/',
  },
  [ApiCommand.scheduleCompanyPeriodicTaskCreate]: {
    method: ApiMethod.post,
    url: '/scheduler/company_periodic_tasks/',
  },
  [ApiCommand.scheduleCompanyPeriodicTaskUpdateSchedule]: {
    method: ApiMethod.patch,
    url: '/scheduler/company_periodic_tasks/{id}/schedule/',
  },
  [ApiCommand.scheduleCompanyPeriodicTaskDelete]: {
    method: ApiMethod.delete,
    url: '/scheduler/company_periodic_tasks/{id}/',
  },
  [ApiCommand.scheduleBillingCompanyGetList]: {
    method: ApiMethod.get,
    url: '/scheduler/billing/company/',
  },
  [ApiCommand.scheduleBillingCompanyCreate]: {
    method: ApiMethod.post,
    url: '/scheduler/billing/company/',
  },
  [ApiCommand.scheduleBillingCompanyUpdate]: {
    method: ApiMethod.patch,
    url: '/scheduler/billing/company/{id}/',
  },
  [ApiCommand.scheduleBillingCompanyDelete]: {
    method: ApiMethod.post,
    url: '/scheduler/billing/company/{id}/',
  },
  [ApiCommand.scheduleBillingCompanyOperatorGetList]: {
    method: ApiMethod.get,
    url: '/scheduler/billing/operator/',
  },
  [ApiCommand.fetchCourtFillingReport]: {
    method: ApiMethod.get,
    url: '/judicial/company_filing_reports/{id}/',
  },
  [ApiCommand.fetchPrintReport]: {
    method: ApiMethod.get,
    url: '/api/account/report/',
  },
  [ApiCommand.fetchPrintReportId]: {
    method: ApiMethod.get,
    url: '/api/account/report/{id}/',
  },
  [ApiCommand.readPrintReport]: {
    method: ApiMethod.post,
    url: '/api/account/report/{id}/mark_as_read/',
  },
  [ApiCommand.deletePrintReport]: {
    method: ApiMethod.post,
    url: '/api/account/report/{id}/mark_as_read/',
  },
  [ApiCommand.companyPermissionsFetchList]: {
    method: ApiMethod.get,
    url: '/api/account/company/{company_id}/permissions/',
  },
  [ApiCommand.companyPermissionsCreate]: {
    method: ApiMethod.post,
    url: '/api/account/company/{company_id}/permissions/',
  },
  [ApiCommand.companyPermissionsUpdate]: {
    method: ApiMethod.patch,
    url: '/api/account/company/{company_id}/permissions/{id}/',
  },
  [ApiCommand.companyPermissionsDelete]: {
    method: ApiMethod.delete,
    url: '/api/account/company/{company_id}/permissions/{id}/',
  },
  [ApiCommand.debtorPhoneNumberCreate]: {
    method: ApiMethod.post,
    url: '/debtor/phone_numbers/',
  },
  [ApiCommand.debtorPhoneNumberUpdate]: {
    method: ApiMethod.patch,
    url: '/debtor/phone_numbers/{id}/',
  },
  [ApiCommand.debtorPhoneNumberDelete]: {
    method: ApiMethod.delete,
    url: '/debtor/phone_numbers/{id}/',
  },
  [ApiCommand.fetchUserAgreement]: {
    method: ApiMethod.get,
    url: '/api/common/public/eula/',
  },
  [ApiCommand.notificationsFetchList]: {
    method: ApiMethod.get,
    url: '/api/account/notification/',
  },
  [ApiCommand.notificationsMarkAsRead]: {
    method: ApiMethod.post,
    url: '/api/account/notification/{id}/mark_as_read/',
  },
  [ApiCommand.fetchDebtorBankAccounts]: {
    method: ApiMethod.get,
    url: '/enforcements/debtor_bank_account/',
  },
  [ApiCommand.updateDebtorBankAccount]: {
    method: ApiMethod.patch,
    url: '/enforcements/debtor_bank_account/{id}/',
  },
  [ApiCommand.fetchDebtorBankAccount]: {
    method: ApiMethod.get,
    url: '/enforcements/debtor_bank_account/{id}',
  },
  [ApiCommand.createDebtorBankAccount]: {
    method: ApiMethod.post,
    url: '/enforcements/debtor_bank_account/',
  },
  [ApiCommand.deleteDebtorBankAccount]: {
    method: ApiMethod.delete,
    url: '/enforcements/debtor_bank_account/{id}',
  },
  [ApiCommand.fetchDebtorAgreements]: {
    method: ApiMethod.get,
    url: '/api/debtor-agreement/',
  },
  [ApiCommand.fetchDebtorAgreement]: {
    method: ApiMethod.get,
    url: '/api/debtor-agreement/{id}/',
  },
  [ApiCommand.createDebtorAgreement]: {
    method: ApiMethod.post,
    url: '/api/debtor-agreement/',
  },
  [ApiCommand.updateDebtorAgreement]: {
    method: ApiMethod.patch,
    url: '/api/debtor-agreement/{id}/',
  },
  [ApiCommand.deleteDebtorAgreement]: {
    method: ApiMethod.delete,
    url: '/api/debtor-agreement/{id}/',
  },
  [ApiCommand.createDebtorExtraAgreement]: {
    method: ApiMethod.post,
    url: '/api/debtor-extra-agreement/',
  },
  [ApiCommand.updateDebtorExtraAgreement]: {
    method: ApiMethod.patch,
    url: '/api/debtor-extra-agreement/{id}/',
  },
  [ApiCommand.deleteDebtorExtraAgreement]: {
    method: ApiMethod.delete,
    url: '/api/debtor-extra-agreement/{id}/',
  },
  [ApiCommand.fetchDebtorCourtDecision]: {
    method: ApiMethod.get,
    url: '/judicial/debtor/{debtor_id}/decisions/{id}/',
  },
  [ApiCommand.addDebtorCourtDecision]: {
    method: ApiMethod.post,
    url: '/judicial/debtor/{debtor_id}/decisions/',
  },
  [ApiCommand.updateDebtorCourtDecision]: {
    method: ApiMethod.patch,
    url: '/judicial/debtor/{debtor_id}/decisions/{id}/',
  },
  [ApiCommand.removeDebtorCourtDecision]: {
    method: ApiMethod.delete,
    url: '/judicial/debtor/{debtor_id}/decisions/{id}/',
  },
  [ApiCommand.getCaptcha]: {
    method: ApiMethod.post,
    url: '/auth/captcha/',
  },
  [ApiCommand.debtorFinanceDataFetchList]: {
    method: ApiMethod.get,
    url: '/api/debtor-finance-data/',
    paramsMap: {
      debtor_data: 'debtor_data',
      type: 'type',
      o: 'ordering',
      limit: 'limit',
      offset: 'offset',
    },
  },
  [ApiCommand.debtorFinanceDataFetchItem]: {
    method: ApiMethod.get,
    url: '/api/debtor-finance-data/{id}/',
  },
  [ApiCommand.debtorFinanceDataCreateItem]: {
    method: ApiMethod.post,
    url: '/api/debtor-finance-data/',
  },
  [ApiCommand.debtorFinanceDataUpdateItem]: {
    method: ApiMethod.patch,
    url: '/api/debtor-finance-data/{id}/',
  },
  [ApiCommand.debtorFinanceDataDeleteItem]: {
    method: ApiMethod.delete,
    url: '/api/debtor-finance-data/{id}/',
  },
  [ApiCommand.companyDebtorStatusesFetchList]: {
    method: ApiMethod.get,
    url: '/debtor_status/filter_consts/{company_id}',
  },
  [ApiCommand.companyDebtorStatusesForCustomStatusFetchList]: {
    method: ApiMethod.get,
    url: '/debtor_status/',
    paramsMap: {
      company: 'company',
      limit: 'limit',
      status: 'status',
    },
  },
  [ApiCommand.rosreestrKeyGetList]: {
    method: ApiMethod.get,
    url: '/rosreestr/key/',
  },
  [ApiCommand.rosreestrKeyGet]: {
    method: ApiMethod.get,
    url: '/rosreestr/key/{id}/',
  },
  [ApiCommand.rosreestrKeyAdd]: {
    method: ApiMethod.post,
    url: '/rosreestr/key/',
  },
  [ApiCommand.rosreestrKeyDelete]: {
    method: ApiMethod.delete,
    url: '/rosreestr/key/{id}/',
  },
  [ApiCommand.pretrialClaimSettingsFetchList]: {
    method: ApiMethod.get,
    url: '/api/account/company-settings/{company_id}/pretrial-claim-settings/',
  },
  [ApiCommand.pretrialClaimSettingsCreateItem]: {
    method: ApiMethod.post,
    url: '/api/account/company-settings/{company_id}/pretrial-claim-settings/',
  },
  [ApiCommand.pretrialClaimSettingsUpdateItem]: {
    method: ApiMethod.patch,
    url: '/api/account/company-settings/{company_id}/pretrial-claim-settings/{id}/',
  },
  [ApiCommand.pretrialClaimSettingsDeleteItem]: {
    method: ApiMethod.delete,
    url: '/api/account/company-settings/{company_id}/pretrial-claim-settings/{id}/',
  },
  [ApiCommand.sendPretrialClaim]: {
    method: ApiMethod.post,
    url: '/pretrial/claim/',
  },
  [ApiCommand.publicCompanySettingsFetchByCompanyDomain]: {
    method: ApiMethod.get,
    url: '/api/account/public-company-settings/{company_domain}/',
  },
  [ApiCommand.lockDebtorNotifications]: {
    method: ApiMethod.post,
    url: '/pretrial/notification_lock/',
  },
  [ApiCommand.unlockDebtorNotifications]: {
    method: ApiMethod.delete,
    url: '/pretrial/notification_lock/{id}/',
  },
  [ApiCommand.debtorDataGetFinances]: {
    method: ApiMethod.get,
    url: '/api/debtors-data/{debtor_id}/finance/',
  },
  [ApiCommand.signDocumentFetchList]: {
    method: ApiMethod.get,
    url: '/documents/crypto_sign/',
  },
  [ApiCommand.signDocumentUpdateItem]: {
    method: ApiMethod.patch,
    url: '/documents/crypto_sign/{id}/',
  },
  [ApiCommand.parseDocument]: {
    method: ApiMethod.post,
    url: '/api/common/parse/{filename}',
  },
  [ApiCommand.fetch1cCategories]: {
    method: ApiMethod.get,
    url: '/api/account/company/subservice/1c/',
  },
  [ApiCommand.download1cConfiguration]: {
    method: ApiMethod.get,
    url: '/api/account/company/configuration/1c/',
  },
  [ApiCommand.debtorDataGetHistory]: {
    method: ApiMethod.get,
    url: '/api/debtors-data-history/',
  },
  [ApiCommand.scheduleDataPoolGetKeysList]: {
    method: ApiMethod.get,
    url: '/externs/datapool/keys/',
  },
  [ApiCommand.scheduleDataPoolDeleteKey]: {
    method: ApiMethod.delete,
    url: '/externs/datapool/keys/{id}/',
  },
  [ApiCommand.scheduleDataPoolAddKeys]: {
    method: ApiMethod.post,
    url: '/externs/datapool/keys/',
  },
  [ApiCommand.scheduleBillingOnlineSolutionsTaskGetList]: {
    method: ApiMethod.get,
    url: '/scheduler/billing_online_solutions/',
  },
  [ApiCommand.scheduleBillingOnlineSolutionsCreateTask]: {
    method: ApiMethod.post,
    url: '/scheduler/billing_online_solutions/',
  },
  [ApiCommand.scheduleBillingOnlineSolutionsDeleteTask]: {
    method: ApiMethod.delete,
    url: '/scheduler/billing_online_solutions/{id}/',
  },
  [ApiCommand.constructorValidateCompany]: {
    method: ApiMethod.post,
    url: '/constructor/validate_company/',
  },
  [ApiCommand.fetchInstruction]: {
    method: ApiMethod.get,
    url: '/api/common/public/mos_ru_instruction/',
  },
  [ApiCommand.companyTemplateTypeFetchList]: {
    method: ApiMethod.get,
    url: '/constructor/company_template_type/',
  },
  [ApiCommand.companyTemplateTypeCreate]: {
    method: ApiMethod.post,
    url: '/constructor/company_template_type/',
  },
  [ApiCommand.companyTemplateTypeDelete]: {
    method: ApiMethod.delete,
    url: '/constructor/company_template_type/{id}/',
  },
  [ApiCommand.documentsExtractFromEgrnFetchList]: {
    method: ApiMethod.get,
    url: '/documents/extract_from_egrn_archive/',
  },
  [ApiCommand.documentsExtractFromEgrnFetchItem]: {
    method: ApiMethod.get,
    url: '/documents/extract_from_egrn_archive/{id}/',
  },
  [ApiCommand.documentsExtractFromEgrnRender]: {
    method: ApiMethod.post,
    url: '/documents/extract_from_egrn_archive/render/',
  },
  [ApiCommand.checkoutToUser]: {
    method: ApiMethod.post,
    url: '/auth/jwt/token/user_access',
  },
  [ApiCommand.uploadDebtorRegions]: {
    method: ApiMethod.post,
    url: 'api/datafile/upload/update_regions/',
  },
  [ApiCommand.uploadDebtorEnrichment]: {
    method: ApiMethod.post,
    url: 'api/datafile/upload/set_enrichment/',
  },
  [ApiCommand.removeDebtorData]: {
    method: ApiMethod.delete,
    url: 'api/delete-debtors-data/{id}/',
  },
  [ApiCommand.removeDebtorsPrevConvictedPeriodData]: {
    method: ApiMethod.delete,
    url: '/api/archive-debtors-data/{id}/previously_convicted_periods/',
  },
  [ApiCommand.archiveDebtorData]: {
    method: ApiMethod.delete,
    url: 'api/archive-debtors-data/{id}/',
  },
  [ApiCommand.schedulerBillingCheck]: {
    method: ApiMethod.post,
    url: 'scheduler/billing/check/',
  },
  [ApiCommand.debtorStatusDocumentRecognition]: {
    method: ApiMethod.post,
    url: '/judicial/document_recognition/',
  },
  [ApiCommand.fetchDebtorCourtApplicationTracker]: {
    method: ApiMethod.get,
    url: '/reference_books/court_application_tracker/',
  },
  [ApiCommand.uploadEnforcementsApplications]: {
    method: ApiMethod.post,
    url: '/enforcements/send_applications/',
  },
  [ApiCommand.fetchEnforcementsApplications]: {
    method: ApiMethod.get,
    url: '/enforcements/applications/',
  },
  [ApiCommand.fetchEnforcementsFsspArticles]: {
    method: ApiMethod.get,
    url: '/enforcements/fssp_article/',
  },
  [ApiCommand.fetchWritoeActions]: {
    method: ApiMethod.get,
    url: '/enforcements/writoe_actions/',
  },
  [ApiCommand.fetchWritoeFfspHistory]: {
    method: ApiMethod.get,
    url: '/enforcements/executive_fssp_history/',
  },
  [ApiCommand.fetchCommonLocales]: {
    method: ApiMethod.get,
    url: '/api/common/custom_locales',
  },
  [ApiCommand.fetchDebtorSourcesOfIncome]: {
    method: ApiMethod.get,
    url: '/enforcements/source_of_income/',
  },
  [ApiCommand.createDebtorSourceOfIncome]: {
    method: ApiMethod.post,
    url: '/enforcements/source_of_income/',
  },
  [ApiCommand.deleteDebtorSourceOfIncome]: {
    method: ApiMethod.delete,
    url: '/enforcements/source_of_income/{id}/',
  },
  [ApiCommand.updateDebtorSourceOfIncome]: {
    method: ApiMethod.patch,
    url: '/enforcements/source_of_income/{id}/',
  },

  [ApiCommand.fetchUsersForDebtor]: {
    method: ApiMethod.get,
    url: '/debtor/assigned_user/',
  },
  [ApiCommand.attachUserToDebtor]: {
    method: ApiMethod.post,
    url: '/debtor/assigned_user/',
  },
  [ApiCommand.removeUserToDebtorBond]: {
    method: ApiMethod.delete,
    url: '/debtor/assigned_user/{id}/',
  },
  [ApiCommand.integrationEsiaTotpQR]: {
    method: ApiMethod.post,
    url: '/api/account/esia_totp_qr/',
  },
  [ApiCommand.getCryptoProCertificate]: {
    method: ApiMethod.get,
    url: '/api/account/crypto_pro_certificate/',
  },
  [ApiCommand.cryptoProCertificate]: {
    method: ApiMethod.post,
    url: '/api/account/crypto_pro_certificate/',
  },
  [ApiCommand.cryptoProCertificateSign]: {
    method: ApiMethod.post,
    url: '/api/account/crypto_pro_certificate/{id}/sign/',
  },
  [ApiCommand.patchCryptoProCertificate]: {
    method: ApiMethod.patch,
    url: '/api/account/crypto_pro_certificate/',
  },
  [ApiCommand.deleteCryptoProCertificate]: {
    method: ApiMethod.delete,
    url: '/api/account/crypto_pro_certificate/{id}/',
  },
};

// @ts-ignore
export const actions: ApiModule['actions'] = {
  async request({ rootGetters, dispatch }, request: ApiRequest) {
    return commonLegacyApiRequest(request);
  },
};
