import { useStore } from 'vuex';
import {
  computed, Ref, ref, VNode, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { Company } from '@/hooks/useCompanies';
import { IBtn, IBtnState, IBtnType } from '@/components/btn/useBtn';
import type { ActiveTableAction, ActiveTableColumn } from '@/components/activeTable/useActiveTable';
import type {
  ActiveFormField,
  BuyLicenseDialogModel,
  BuyServiceDialogModel,
} from '@/hooks/useActiveForm';
import { Employee } from '@/hooks/useEmployees';
import { Debtor } from '@/hooks/useDebtors';
import { ProductionType } from '@/hooks/useConstructor';
import { DebtorCustomDocumentType, UpdateDebtorPochtaTrackModel } from '@/hooks/useDocumentsApi';
import { CompanyLicense, FinanceService } from '@/hooks/useFinance';
import { IDebtorCompositeUpdateConfigDialog } from '@/hooks/useDebtorCompositeUpdate';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { RouterKey } from '@core/symbols';
import { usePreventLeaveController } from '@/hooks/usePreventLeave';
import {
  IConstructorReportTemplateColumnStyle,
} from '@/components/dialog/dialogs/constructorReportTemplateColumnStyle/ConstructorReportTemplateColumnStyle.vue';
import { Certificate } from '@urrobot/core/plugins/cryptoPro/getUserCertificates';
import { CourtDetailsPayload } from '@/components/dialog/dialogs/debtor/tabs/courts/types';
import { ExchangeImportRequest } from '@/pages/exchange/import/useExchangeImport';
import { DocumentParsingTemplate } from '@/hooks/useDocumentParsingTemplatesApi';
import { AddAddressesPayload } from '@/pages/admin/courts/main/useAddAddressesDialog';
import {
  IDialogIntegration,
  IntegrationDialog,
} from '@/pages/exchange/integration/dialogs/integrationDialogs';
import { HasPhones } from '@/components/dialog/dialogs/debtor/tabs/common/tabs/tenants/utils';
import { AisGorodDialog } from '@/pages/debtors/_module/tableActions/aisGorodUpdate/types';
import { BorDialog } from '@/pages/debtors/_module/tableActions/borUpdate/types';
import { MegapolisUpdatePayload } from '@/pages/debtors/_module/tableActions/megapolisUpdate/types';
import { PikUpdatePayload } from '@/pages/debtors/_module/tableActions/pikUpdate/types';
import { AsuDialog } from '@/pages/debtors/_module/tableActions/asuUpdate/types';

export enum IDialogComponent {
  aisGorodUpdate = 'aisGorodUpdate',
  borUpdate = 'borUpdate',
  megapolisUpdate = 'megapolisUpdate',
  pikUpdate = 'pikUpdate',
  asuUpdate = 'asuUpdate',
  addCompany = 'addCompany',
  company = 'company',
  addEmployee = 'addEmployee',
  confirm = 'confirm',
  tableDisplaySettings = 'tableDisplaySettings',
  actionsSelector = 'actionsSelector',
  model = 'model',
  debtorStatus = 'debtorStatus',
  print = 'print',
  file = 'file',
  egrn = 'egrn',
  debtor = 'debtor',
  listenFile = 'listenFile',
  reportingChartDialog = 'reportingChartDialog',
  buyService = 'buyService',
  buyLicense = 'buyLicense',
  content = 'content',
  contentSmall = 'contentSmall',
  debtorCompositeUpdateConfig = 'debtorCompositeUpdateConfig',
  debtorAgreement = 'debtorAgreement',
  debtorBankAccounts = 'debtorBankAccounts',
  phonesDialog = 'phonesDialog',
  eulaDialog = 'eulaDialog',
  automatizing = 'automatizing',
  constructorReportTemplateColumnStyle = 'constructorReportTemplateColumnStyle',
  localEncryptSignSelect = 'localEncryptSignSelect',
  confirmCopyClipboard = 'confirmCopyClipboard',
  courtDetails = 'courtDetails',
  courtCaseDetails = 'courtCaseDetails',
  fsspDetails = 'fsspDetails',
  attachEmployeeToDebtor = 'attachEmployeeToDebtor',
  editImportedFileLayout = 'editImportedFileLayout',
  addAddressesToCourt = 'addAddressesToCourt',
  debtorDocTypes = 'debtorDocTypes',
  voiceCallQueue = 'voiceCallQueue',
  messagesDialog = 'messagesDialog',
}

export type IDialogComponentAll = IDialogComponent | IntegrationDialog

export type ConfirmDialogButton = {
  key: string;
  label?: IBtn['label'];
  state?: IBtn['state'];
  onClick?: (() => void);
  icon?: string;
  type?: IBtnType;
  isVisible?: boolean;
  id: string;
}

export type ConfirmDialogPayload = {
  key?: string;
  title?: string;
  message?: string;
  cancelButton?: string;
  buttons?: Array<ConfirmDialogButton>;
  extraContent?: Ref<VNode>;
}

export type ModelPayload = {
  record?: Record<any, any>;
  fields: Array<ActiveFormField<any>>;
  isEditable?: boolean;
  signal?: string;
  wide?: boolean;
  isToggleable?: boolean;
  isSaving?: boolean;
  isLoading?: boolean;
  withPrevNext?: boolean;
  withEditing?: boolean;
  initEditing?: boolean;
  saveOnNavigate?: boolean;
  goPrev?: () => Promise<void>;
  goNext?: () => Promise<void>;
  mKey?: string;
}

export type TableDisplaySettingsPayload = {
  columns: Array<Pick<ActiveTableColumn<any>, 'key' | 'label' | 'isRequired'>>;
  limit: number;
  visibleColumns: Array<ActiveTableColumn<any>['key']>;
  tableKey: string;
}

export type ActionsSelectorPayload = {
  tableKey: string;
  actions: Array<ActiveTableAction<any, any>>;
  visibleActions: Array<ActiveTableAction<any, any>['key']>;
}

export type DebtorStatusPayload = {
  debtorIds: Array<Debtor['pk']>;
  productionType: ProductionType.pretrial | ProductionType.judicial;
  debtorMainIds: Array<Debtor['debtor_main_profile']['id']>;
}

export type PrintPayload = {
  debtorIds: Array<Debtor['pk']>;
  productionType: ProductionType;
  someoneIsOrganization: boolean;
}

export type EgrnPayload = {
  debtorIds: Array<Debtor['pk']>;
  productionType: ProductionType;
}

export type DebtorPayload = {
  id: Debtor['pk'];
  productionType: ProductionType;
  name: string;
  personalAccount: string;
  isOrganization: boolean;
  tableOffset: number;
}

export type FilePayload = {
  title?: string;
  url: string;
  withPreview?: boolean;
  withCopy?: boolean;
  reports?: { name: string; url: string}[];
}

export type ListenPayload = {
  name: string;
  file: string;
}

export type BuyServicePayload = {
  title: string;
  autoRenewalAvailable: boolean;
  service: FinanceService;
  chosenRateId: number;
  onSave: (model: BuyServiceDialogModel) => void;
}

export type BuyLicensePayload = {
  licenses: Array<CompanyLicense>;
  chosenLicense: number | null;
  onSave: (model: BuyLicenseDialogModel) => void;
}

export type DebtorCustomDocumentTypePayload = {
  productionType: ProductionType;
}

export type IDialog = {
  id?: string;
  component: IDialogComponentAll;
  key?: string;
  title?: string;
  payload?: Record<any, any>;
  addInRoute?: boolean;
  isCloseable?: boolean;
  preventCloseOnRouteChange?: boolean;
  params?: Record<any, any>;
  ionOptions?: any;
  ionDialogClassList?: string[];
} & (
  IDialogIntegration
  | {
  component: IDialogComponent.addCompany;
} | {
  component: IDialogComponent.company;
  payload: {
    id: Company['id'];
    isInitiallyEditing?: boolean;
  };
} | {
  component: IDialogComponent.addEmployee;
  payload: {
    companyId?: Company['id'];
    employee?: Employee;
  };
} | {
  component: IDialogComponent.confirm;
  payload: ConfirmDialogPayload;
} | {
  component: IDialogComponent.courtCaseDetails;
  payload: { courtCaseId: number };
}| {
  component: IDialogComponent.fsspDetails;
  payload: {
    companyId?: Company['id'];
    id: number;
  };
} | {
  component: IDialogComponent.tableDisplaySettings;
  payload: TableDisplaySettingsPayload;
} | {
  component: IDialogComponent.model;
  payload: ModelPayload;
} | {
  component: IDialogComponent.actionsSelector;
  payload: ActionsSelectorPayload;
} | {
  component: IDialogComponent.debtorStatus;
  payload: DebtorStatusPayload;
} | {
  component: IDialogComponent.print;
  payload: PrintPayload;
} | {
  component: IDialogComponent.file;
  payload: FilePayload;
} | {
  component: IDialogComponent.egrn;
  payload: EgrnPayload;
} | {
  component: IDialogComponent.debtor;
  payload: DebtorPayload;
} | {
  component: IDialogComponent.listenFile;
  payload: ListenPayload;
} | {
  component: IDialogComponent.reportingChartDialog;
  payload: {
    model: any;
  };
} | {
  component: IDialogComponent.content;
  addInRoute: false;
  payload: {
    title: string;
    content: any;
  };
} | {
  component: IDialogComponent.contentSmall;
  addInRoute: false;
  payload: {
    title: string;
    content: any;
  };
} | {
  component: IDialogComponent.buyService;
  addInRoute: false;
  payload: BuyServicePayload;
} | {
  component: IDialogComponent.buyLicense;
  addInRoute: false;
  payload: BuyLicensePayload;
} | {
  component: IDialogComponent.aisGorodUpdate;
  addInRoute: false;
  payload: AisGorodDialog;
} | {
  component: IDialogComponent.borUpdate;
  addInRoute: false;
  payload: BorDialog;
} | {
  component: IDialogComponent.asuUpdate;
  addInRoute: false;
  payload: AsuDialog;
} | {
  component: IDialogComponent.megapolisUpdate;
  addInRoute: false;
  payload: MegapolisUpdatePayload;
} | {
  component: IDialogComponent.pikUpdate;
  addInRoute: false;
  payload: PikUpdatePayload;
} | {
  component: IDialogComponent.debtorCompositeUpdateConfig;
  addInRoute: false;
  payload: IDebtorCompositeUpdateConfigDialog;
} | {
  component: IDialogComponent.debtorBankAccounts;
  addInRoute: false;
  payload: any;
} | {
  component: IDialogComponent.eulaDialog;
  addInRoute: false;
} | {
  component: IDialogComponent.debtorAgreement;
  payload: {
    debtorId: number;
    companyId: number;
    agreementId?: number;
  };
  addInRoute: false;
} | {
  component: IDialogComponent.phonesDialog;
  payload: {
    model: HasPhones;
    debtor: Debtor;
    productionType: ProductionType;
  };
} | {
  component: IDialogComponent.automatizing;
  payload: {
    productionType: ProductionType;
  };
} | {
  component: IDialogComponent.constructorReportTemplateColumnStyle;
  payload: {
    modelValue: IConstructorReportTemplateColumnStyle['modelValue'];
    propsToEdit: IConstructorReportTemplateColumnStyle['propsToEdit'];
    onSubmit: (payload: IConstructorReportTemplateColumnStyle['modelValue']) => void;
  };
  addInRoute: false;
} | {
  component: IDialogComponent.localEncryptSignSelect;
  payload: {
    onSignSelected: (cert: Certificate) => void;
  };
} | {
  component: IDialogComponent.confirmCopyClipboard;
  payload: {
    onConfirm: () => void;
  };
  addInRoute: false;
} | {
  component: IDialogComponent.courtDetails;
  payload: CourtDetailsPayload;
  addInRoute: false;
} | {
  component: IDialogComponent.attachEmployeeToDebtor;
  payload: {
    selected: number[];
  };
  addInRoute: false;
} | {
  component: IDialogComponent.editImportedFileLayout;
  payload: {
    companyId: number;
    model: ExchangeImportRequest;
    templates: Ref<DocumentParsingTemplate[]>;
  };
  addInRoute: false;
} | {
  component: IDialogComponent.addAddressesToCourt;
  payload: AddAddressesPayload;
  addInRoute: false;
} | {
  component: IDialogComponent.debtorDocTypes;
  payload: DebtorCustomDocumentTypePayload;
  addInRoute: false;
} | {
  component: IDialogComponent.voiceCallQueue;
  payload: { companyId: number };
  addInRoute: false;
} | {
  component: IDialogComponent.messagesDialog;
  addInRoute: false;
})

export type CloseDialogCallback = (() => void) & { id: string }

export const useDialog = () => {
  const store = useStore();
  const { t } = useI18n();

  const dialogs = computed<Array<IDialog>>(() => (
    store.getters['layout/dialogs']
  ));

  const hasDialogs = computed(() => dialogs.value.length);

  const updateDialogById = (
    dialog: IDialog,
  ) => store.commit('layout/updateDialogById', dialog);

  const showDialog = async (
    dialog: IDialog,
  ): Promise<CloseDialogCallback> => store.dispatch('layout/showDialog', dialog);

  const closeDialogById = async (
    id: IDialog['id'],
  ) => store.dispatch('layout/closeDialogById', id);

  const closeDialogByComponent = async (
    component: IDialogComponent,
  ) => store.dispatch('layout/closeDialogByComponent', component);

  const confirmDialog = ({
    key,
    title,
    buttons,
    message,
    cancelButton = 'cancel',
    withConfirmation = true,
    withCancel = true,
    cancelLabel,
    confirmLabel,
    confirmMixin,
    cancelMixin,
    extraContent,
  }: Partial<ConfirmDialogPayload & {
    cancelLabel?: string;
    confirmLabel?: string;
    withConfirmation?: boolean;
    withCancel?: boolean;
    confirmMixin?: Partial<ConfirmDialogButton>;
    cancelMixin?: Partial<ConfirmDialogButton>;
    extraContent?: Ref<VNode>;
  }>) => new Promise<{
    result: boolean;
    closeDialog:(() => void);
      }>(async (resolve) => {
        const closeDialog = await showDialog({
          component: IDialogComponent.confirm,
          addInRoute: false,
          payload: {
            key,
            title,
            message,
            cancelButton,
            extraContent,
            buttons: buttons?.map((btn) => ({
              ...btn,
              onClick: () => {
                btn?.onClick?.();
                closeDialog();
              },
            })) || ([
              withCancel && {
                key: 'cancel',
                id: cancelMixin?.id ?? `confirm_dialog_${key}_cancel_btn`,
                label: cancelLabel || t('other.cancel'),
                onClick: () => {
                  resolve({ result: false, closeDialog });
                },
                state: IBtnState.tertiary,
                ...cancelMixin,
              },
              withConfirmation && {
                key: 'confirm',
                id: confirmMixin?.id ?? `confirm_dialog_${key}_confirm_btn`,
                label: confirmLabel || t('other.confirm'),
                onClick: () => {
                  resolve({ result: true, closeDialog });
                },
                type: 'submit',
                state: IBtnState.primary,
                ...(confirmMixin || {}),
              },
            ] as Array<ConfirmDialogButton | boolean>)
              .filter(Boolean) as Array<ConfirmDialogButton>,
          },
        });
      });

  return {
    dialogs,
    hasDialogs,

    showDialog,
    closeDialogById,
    updateDialogById,
    closeDialogByComponent,

    confirmDialog,
  };
};

export const useDialogRouteParam = <T extends string | null>(
  field: string,
  defaultValue: T,
  isActive: Ref<boolean>,
  withLeaveController?: boolean,
) => {
  const leaveController = usePreventLeaveController();
  const router = useProtectedInject(RouterKey);
  const value = ref<T>(defaultValue);
  watch(computed(() => router.currentRoute.value?.hash.substring(1)), (hash) => {
    if (!isActive.value) {
      return;
    }
    if (hash) {
      value.value = JSON.parse(hash)[field] || defaultValue;
    }
  }, {
    immediate: true,
  });
  const computedValue = computed<T>({
    get: () => (value.value) as T,
    set: async (val) => {
      if (withLeaveController && leaveController) {
        const result = await leaveController.leaveGuard();
        if (!result) {
          return;
        }
      }
      if (!isActive.value) {
        return;
      }
      try {
        await router.push({
          ...router.currentRoute.value,
          hash: `#${JSON.stringify({
            ...JSON.parse(router.currentRoute.value.hash.substring(1)),
            [field]: val,
          })}`,
        });
      } catch (e) {
        value.value = val as typeof value.value;
      }
    },
  });
  return [computedValue];
};
